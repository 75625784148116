import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { GlobalService } from 'src/app/services/global.service';
import { TeamsService } from 'src/app/services/teams.service';
@Component({
  selector: 'app-client-matter',
  templateUrl: './client-matter.component.html',
  styleUrls: ['./client-matter.component.scss']
})
export class ClientMatterComponent  {
  public teamsTemplates: any = [];
  public templateSelected = {};
  public teamConnection:any;

  @Input("appConfig") set onSetAppConfig(data: any) {
    this.appConfig = data;
    this.customizedClientMatters = this.appConfig?.selectedClientMatters ?
      this.appConfig?.selectedClientMatters : [];
  }
  @Output() onChangeWorkspaceInfo: EventEmitter<any> = new EventEmitter<any>();  
  appConfig: any = {};
  cabinet: any = {
    data: [],
    message:"",
    selected: {},
    isFetching: false
  };
  client: any = {
    data: [],
    message:"",
    selected: {},
    isFetching: false,
    clientInfo: { name: "Client" }
  };
  matter: any = {
    data: [],
    message:"",
    selected: {},
    isFetching: false,
    matterInfo: { name: "Matter" }
  };

  httpResponse: any = {};
  customizedClientMatters: any = [];
  customizedClientMatterIndex: number = -1;
  isFetchingWorkspaceInfo: boolean = false;
 
  lookupDataCabinet: any = {};
  lookupDataClient: any = {};
  lookupDataMatter: any = {};
  constructor(
    private ndSrvc: NetDocumentViewerService,
    private netDocShareSrvc: NetDocumentViewerService,
    private toastr: ToastrService,
    private global:GlobalService,
    private teamsService:TeamsService
  ) {
    this.fetchCabinets()   
  }
   
  resetCabinets(data: any = null) {    
    this.cabinet.selected = {};   
  }
  fetchCabinets(data: any=null){
    this.cabinet.isFetching = true;
    if (data?.fetchBy == "search" && this.lookupDataCabinet?.data?.length>0) {      
      let searchData = this.lookupDataCabinet?.data.filter((item: any) => JSON.stringify(item?.name).toLowerCase()?.indexOf(data?.searchstring?.toLowerCase()) > -1);
      this.cabinet = {
        data: searchData,
        message: searchData?.length == 0 ? "No Cabinet found" : ""
      }
      this.cabinet.isFetching = false;
    } else {
      this.ndSrvc.getCabinets().subscribe((resData: any) => {
        this.cabinet.isFetching = false;
        if (resData.status == "success") {
          this.cabinet = { data: resData.data, message: resData.data.length == 0 ?  "Cabinet Not found": "" };
          this.lookupDataCabinet=this.cabinet;
          
        } else {
          this.cabinet = { data: [], message: "Cabinet Not found" };
        }
        
      })
    }
  }
  getClients(cabinet:any,data: any=null, searchQuery = null, skip = 0) {
    this.client.isFetching = true;
    if (data?.fetchBy == "search" && this.lookupDataClient?.length>0) {      
      let searchData = this.lookupDataClient?.filter((item: any) => JSON.stringify(item?.key).toLowerCase()?.indexOf(data?.searchstring?.toLowerCase()) > -1);
      this.client={
        data : searchData,
        isFetching : false,       
        message: searchData?.length == 0 ? "No Client found" : "",
        clientInfo:this.client?.clientInfo
      }
      this.cabinet.isFetching = false;
    } else {
    if(skip === 0) this.client.data = [];
    const clientAttrNum = cabinet.wsOrgAttrNum ? cabinet.wsOrgAttrNum : cabinet.wsAttrNum;
    this.ndSrvc.getLookUpData(cabinet.repositoryId, clientAttrNum, null, searchQuery, skip).subscribe(res => {
      const clientData = res?.data?.rows ? res.data.rows : [];
      this.lookupDataClient=clientData;
      this.client={
        data : skip ? this.client.data.concat(clientData) : clientData,
        isFetching : false,
        overflow : res?.data?.overflow,
        clientInfo:this.client?.clientInfo,
        message: clientData?.length == 0 ? "No Client found" : ""
      }
   

    });
    this.client.isFetching=false;
  }
   
}
  getClientMatterInfo(cabinet: any) {
    this.ndSrvc.getProfileAttributes(cabinet.id).subscribe(profileRes => {
      let profileData = profileRes?.data ? profileRes.data : [];
      let clientAttrNum = cabinet.wsOrgAttrNum ? cabinet.wsOrgAttrNum : cabinet.wsAttrNum;
      let matterAttrNum = cabinet.wsOrgAttrNum ? cabinet.wsAttrNum : null;
      if(!matterAttrNum){
        let profileFound = profileData?.find((profile: any) => profile?.name?.toLowerCase()+'s' === cabinet?.wsAttrPluralName?.toLowerCase());
        clientAttrNum = profileFound.linked ? profileFound.linked : profileFound.id;
        matterAttrNum = profileFound.linked ? profileFound.id : null;
      }
      this.client.clientInfo = profileData?.find((profile: any) => profile.id === clientAttrNum);
      this.matter.matterInfo = profileData?.find((profile: any) => matterAttrNum && profile.id === matterAttrNum);
      if(this.customizedClientMatters.length>0) this.getCustomzedClients();
    });
  }

 

  getMatters(data:any=null,searchQuery = null, skip = 0) {
    
    if (data?.fetchBy == "search" && this.lookupDataMatter?.length>0) {      
      let searchData = this.lookupDataMatter?.filter((item: any) => JSON.stringify(item?.key).toLowerCase()?.indexOf(data?.searchstring?.toLowerCase()) > -1);
      this.matter={
        data : searchData,
        isFetching : false, 
        matterInfo:this.matter?.matterInfo ,    
        message: searchData?.length == 0 ? "No Matter found" : ""

      }
      this.cabinet.isFetching = false;
    }else if(data?.fetchBy == "toggle")
      {
       let selectedclient=this.client?.data?.filter(item=>item.key.includes(this.matter?.selected?.parent)); 
       if(selectedclient.length==1){      
        this.onSelectClient(selectedclient[0]);
      }
        this.ndSrvc.getLookUpData(this.cabinet.selected.repositoryId,
          this.matter?.matterInfo?.id, this.matter?.selected?.parent, searchQuery, skip).subscribe(res => {
            const matterData = res?.data?.rows ? res.data.rows : [];
            this.lookupDataMatter=matterData;
            this.matter={       
                data : skip ? this.client.data.concat(matterData) : matterData,
                isFetching : false,
                overflow : res?.data?.overflow,
                matterInfo:this.matter?.matterInfo ,  
                message: matterData?.length == 0 ? "No Matters found" : ""        
            }
          });
      }
     else {
    if (this.cabinet?.selected?.repositoryId) {
      this.matter.isFetching = true;
      let key="";
      if(this.client?.selected?.key){
        key=this.client.selected.key;
      }
      if(data?.fetchBy == "search")searchQuery=data?.searchstring;      
      if(skip === 0) this.matter.data = [];
      this.ndSrvc.getLookUpData(this.cabinet.selected.repositoryId,
      this.matter?.matterInfo?.id, key, searchQuery, skip).subscribe(res => {
        const matterData = res?.data?.rows ? res.data.rows : [];
        this.lookupDataMatter=matterData;
        this.matter={       
            data : skip ? this.matter.data.concat(matterData) : matterData,
            isFetching : false,
            overflow : res?.data?.overflow,
            matterInfo:this.matter?.matterInfo ,
            message: matterData?.length == 0 ? "No Matters found" : ""        
        }
      });
    }
  }
  }

  onScroll(event: any, type: string) {
    const dropdownMenu: any = event?.target?.children[0];
    const isFetching = type === "client" ? this.client.isFetching : this.matter.isFetching;
    const overflow = type === "client" ? this.client.overflow : this.matter.overflow;
    if (event.target.scrollTop + event.target.offsetHeight + 100 > dropdownMenu.offsetHeight && !isFetching && overflow) {
      if(type === "client") this.getClients(this.cabinet.selected, null, this.client.clients.length);
      else if(type === "matter") this.getMatters(null, this.matter.matters.length);
    }
  }

  onSourceCabinetChange(cabinet: any) {
    this.cabinet.data?.forEach((cab: any) => {
      cab.isSelected = false;     
    });
    cabinet.isSelected = true;    
    this.getClientMatterInfo(cabinet);
    this.cabinet.selected = cabinet;
    this.client.clientInfo = {};
    this.matter.matterInfo = {};
    this.client.clients = [];
    this.matter.matters = [];
    this.reset("both");
    if(this.customizedClientMatters.length===0) this.getClients(cabinet);
  }

  getCustomzedClients(){
    this.customizedClientMatterIndex = this.customizedClientMatters.findIndex((cabinet:any) => cabinet.cabinet.id == this.cabinet?.selected?.id);
    this.client.isFetching = true;
    if (this.customizedClientMatters.length > 0 && this.customizedClientMatters[this.customizedClientMatterIndex]){
      this.appConfig.columnProperties = this.customizedClientMatters[this.customizedClientMatterIndex].columnProperties || [];
    }
    if (this.appConfig.showAccessibleClientMatter) {
      if (this.httpResponse.validateClientMatter) this.httpResponse.validateClientMatter.unsubscribe();
      if (this.customizedClientMatters[this.customizedClientMatterIndex].isRetrieved) {
        this.client.clients = this.customizedClientMatters[this.customizedClientMatterIndex].clients;
        this.client.isFetching = false;
      } else {
        if(!this.matter?.matterInfo) this.customizedClientMatters[this.customizedClientMatterIndex].matterId = "";
        this.httpResponse.validateClientMatter = this.netDocShareSrvc.getAccessibleClientMatter(this.customizedClientMatters[this.customizedClientMatterIndex]).subscribe(res => {
          this.client.isFetching = false;
          if (res.status == "success" && res?.data?.clients) {
            this.client.clients = res.data.clients;
            this.customizedClientMatters[this.customizedClientMatterIndex].clients = this.client.clients;
            this.customizedClientMatters[this.customizedClientMatterIndex].isRetrieved = true;
            if (this.client.clients.length == 1) this.onSelectClient(this.client.clients[0]);
          }
        });
      }
    } else {
      this.client.clients = this.customizedClientMatters[this.customizedClientMatterIndex].clients;
      if (this.client.clients.length == 1) this.onSelectClient(this.client.clients[0]);
      this.client.isFetching = false;
    }
  }

  onSelectClient(client: any) {   
    client.name = `${client.key}${client.description ? ' - ' + client.description : ''}`;
    this.client.selected = client;    
    if(this.customizedClientMatters.length===0 || !this.appConfig.showAccessibleClientMatter){
      if (this.matter?.matterInfo?.id){
        this.getMatters();
      }      
    }
   if(!this.matter?.matterInfo) this.onClickGo();
  }

  onSelectMatter(matter: any) {
    if(matter!=null){
    matter.name = `${matter.key}${matter.description ? ' - ' + matter.description : ''}`;
    this.matter.selected = matter;
    this.onClickGo();
    if(this.matter.isSearching) {
      this.matter.isSearching = false;
      this.getMatters();
    }
  }
  
  }

  search(type: string, event: any, dropdown: any) {
    const searchQuery = event?.target?.value?.split(" - ");
    if (type === "client") {
      this.client.selected.name = event?.target?.value;
      if (this.customizedClientMatters.length === 0) {
        this.client.isSearching = true;
        this.getClients(this.cabinet.selected, searchQuery);
      } else {
        this.client.clients = this.customizedClientMatters[this.customizedClientMatterIndex].clients?.filter((item:any) => {
          return JSON.stringify(item).toLowerCase().indexOf(searchQuery) > -1
        });
      }
    } else if (type === "matter") {
      this.matter.selected.name = event?.target?.value;
      if (this.customizedClientMatters.length === 0) {
        this.matter.isSearching = true;
        this.getMatters(searchQuery);
      } else {
        this.matter.matters = this.customizedClientMatters[this.customizedClientMatterIndex].matters?.filter((item:any) => {
          return JSON.stringify(item).toLowerCase().indexOf(searchQuery) > -1
        });
      }
    }
    if (dropdown) dropdown.open();
  }

  reset(type: string) {
    if (type === "client" || type === "both") {
      this.client.selected = {};
      this.matter.selected = {};
    }
    if (type === "matter" || type === "both") this.matter.selected = {};
  }

  onClickGo() {
    this.isFetchingWorkspaceInfo = true;
    let client = this.client?.selected?.name?.split(" - ")[0];
    let matter = this.matter?.selected?.name?.split(" - ")[0];
    this.ndSrvc.getWorkspaceInfoByClientMatter(this.cabinet?.selected?.id, client?.trim(), matter?.trim()).subscribe(res => {
      if (res.status === "success" && res.data) {
        res.data.cabinetId = this.cabinet.selected.id;
        res.data.selectedCabinet=this.cabinet?.selected;
        res.data.SelectedClient=this.client.selected;       
        this.onChangeWorkspaceInfo.emit(res.data);
      } else {
        if(client == undefined){
          const errorMsg = `Please select ${this.client?.clientInfo?.name}`;
        this.toastr.error(errorMsg);
        }else if(matter == undefined){
          const errorMsg = `Please select ${this.matter?.matterInfo?.name}`;
          this.toastr.error(errorMsg);
        }else{
          const errorMsg = res?.error?.error?.error || "Failed to get workspace details";
          this.toastr.error(errorMsg);
        }
        // const errorMsg = res?.error?.error?.error || "Failed to get workspace details";
        // this.toastr.error(errorMsg);
      }
      this.isFetchingWorkspaceInfo = false;
    });
  }
}
