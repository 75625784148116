<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Planner</h4>
    <span class="close-icon" (click)="closeModal()">&times;</span>
</div>
<div class="modal-body">
    <div class="planner-popup">
        <div class="planner-body">
            <!-- Add and Remove the "selected" class when user selects the radio button -->
            <div class="listBox {{createPlanType == 'createNew' ? 'selected' : ''}}">
                <div class="title-box">
                    <input type="radio" id="createNew" name="create" value="createNew" [(ngModel)]="createPlanType">
                    <span>Create a new plan</span>
                </div>
                <div class="list">
                    <div class="top-box-title">
                        Tab Name
                    </div>
                    <div class="top-box">
                        <input class="task" name="name" type="text" placeholder="Task" [(ngModel)]="tabName" />
                    </div>
                    <div class="details">
                        Note: If you want to add an existing premium plan or create a new one, you'll need to do it from
                        the Project tab app.
                    </div>
                </div>
            </div>
            <div class="listBox {{createPlanType == 'useExisting' ? 'selected' : ''}}">
                <div class="title-box">
                    <input type="radio" id="useExisting" name="create" value="useExisting" [(ngModel)]="createPlanType">
                    <span>Use an existing plan from this team</span>
                </div>
                <div class="list">
                    <div class="top-box-title">
                        Looks like this team has existing plans. Select an existing plan to add it as a tab.
                    </div>
                    <div class="top-box">
                        <select name="dropdown" class="task" [(ngModel)]="existingPlans.selected">
                            <option disabled [ngValue]="null">Existing Plans</option>
                            <option *ngFor="let plan of existingPlans.plans" [ngValue]="plan">
                                {{plan.title}}
                            </option>
                            <option disabled class="text-center" *ngIf="existingPlans.isFetching">
                                Loading...
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" [disabled]="isCreatingTab" (click)="closeModal()">
        Cancel
    </button>
    <button type="button" class="btn im-primary-btn"
        [disabled]="isCreatingTab || (createPlanType == 'createNew' && !tabName?.trim()) || (createPlanType == 'useExisting' && !existingPlans.selected?.id)"
        (click)="onCreatePlannerTab()">
        Create
    </button>
</div>