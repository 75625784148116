//Modules

import { ElectronService, NgxElectronModule } from 'ngx-electron';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './pages/login/login.component';
import { NetDocAuthComponent } from './pages/net-doc-auth/net-doc-auth.component';
import { NetDocLoginComponent } from './pages/net-doc-login/net-doc-login.component';
import { ContainerTopComponent } from './components/container-top/container-top.component';
import { FooterComponent } from './components/footer/footer.component';

import { HelperService } from './services/helper';
import { NetDocumentViewerService } from './services/netdocument-viewer';
import { ErrorHandler, NgModule } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RouteConfigModule } from './app-routing.module';
import { ToasterMessageComponent } from './components/toaster-message/toaster-message.component';
import { ToastrModule } from 'ngx-toastr';
import { SyncFrequencyComponent } from './components/sync-frequency/sync-frequency.component';
import { ErrorHandlerService } from './services/error-handler.service';
import { LogService } from './services/log.service';
import { HttpInterseptorService } from './services/http-interseptor.service';
import { ProvisioningComponent } from './pages/provisioning/provisioning.component';
import { TeamsComponent } from './components/teams/teams.component';
import { TeamsSettingsComponent } from './pages/teams-settings/teams-settings.component';
import { TeamsService } from './services/teams.service';
import { TenantPickerComponent } from './components/tenant-picker/tenant-picker.component';
import { NetDocumentsComponent } from './components/netdocuments/netdocuments.component';
import { FilterWorkspacenamePipe } from './pipes/filter-workspacename.pipe';
import { FilterCabinetfolderPipe } from './pipes/filter-cabinetfolder.pipe';
import { CreateTeamsOptionsComponent } from './components/create-teams-options/create-teams-options.component';
import { CreateTeamsComponent } from './pages/create-teams/create-teams.component';
import { TeamGroupsComponent } from './components/team-groups/team-groups.component';
import { TeamChannelsComponent } from './components/team-channels/team-channels.component';
import { TeamTabsComponent } from './components/team-tabs/team-tabs.component';
import { ConnectionNamePipe } from './filters/connection-name.pipe';
import { CreateTeamsFTComponent } from './pages/create-teams-template-from-existing/create-teams-template-from-existing.component';
import { CreateChannelsTabsAppsComponent } from './pages/create-channels-tabs-apps/create-channels-tabs-apps.component';
import { GlobalService } from './services/global.service';
import { CreateChannelComponent } from './components/create-channel/create-channel.component';
import { CreateTabComponent } from './components/create-tab/create-tab.component';
import { FilterTeamsPipe } from './filters/filter-teams.pipe';
import { FilterChannelsPipe } from './filters/filter-channels.pipe';
import { TeamCreationStepsComponent } from './components/team-creation-steps/team-creation-steps.component';
import { ChannelsConfigComponent } from './components/channels-config/channels-config.component';
import { SyncNetDocLocationComponent } from './components/sync-net-doc-location/sync-net-doc-location.component';
import { BulkUploadComponent } from './pages/bulk-upload/bulk-upload.component';
import { FilterTemplatePipe } from './filters/filter-template.pipe';
import { EditTeamComponent } from './components/edit-team/edit-team.component';
import { BulkTeamCreationComponent } from './pages/bulk-team-creation/bulk-team-creation.component';
import { BulkConfigUpdateComponent } from './pages/bulk-config-update/bulk-config-update.component';
import { TeamNamePipe } from './filters/team-name.pipe';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { MembersComponent } from './components/members/members.component';
import{RequestsComponent} from './components/requests/requests.component';
import { CreateRequestComponent } from './components/create-request/create-request.component';
import { ClientMatterComponent } from './components/client-matter/client-matter.component';
import { NdComboBoxComponent } from './components/nd-combo-box/nd-combo-box.component';
import { IconComponent } from './components/icon/icon.component';
import { SpService } from './services/sp.service';
import { BulkTeamEditComponent } from './components/bulk-team-edit/bulk-team-edit.component';
import { TeamAppsComponent } from './components/team-apps/team-apps.component';
import { CreatePlannerTabComponent } from './components/create-planner-tab/create-planner-tab.component';


//Components

// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { ElectronService } from 'ngx-electron';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NetDocLoginComponent,
    NetDocAuthComponent,
    ContainerTopComponent,
    FooterComponent,
    ToasterMessageComponent,
    SyncFrequencyComponent,
    ProvisioningComponent,
    TeamsComponent,
    TeamsSettingsComponent,
    TenantPickerComponent,
    NetDocumentsComponent,
    FilterWorkspacenamePipe,
    FilterCabinetfolderPipe,
    CreateTeamsOptionsComponent,
    CreateTeamsComponent,
    TeamGroupsComponent,
    TeamChannelsComponent,
    TeamTabsComponent,
    ConnectionNamePipe,
    CreateTeamsFTComponent,
    CreateChannelsTabsAppsComponent,
    CreateChannelComponent,
    CreateTabComponent,
    FilterTeamsPipe,
    FilterChannelsPipe,
    TeamCreationStepsComponent,
    ChannelsConfigComponent,
    SyncNetDocLocationComponent,
    BulkUploadComponent,
    FilterTemplatePipe,
    EditTeamComponent,
    BulkTeamCreationComponent,
    BulkConfigUpdateComponent,
    TeamNamePipe,
    TemplateEditorComponent,
    MembersComponent,
    RequestsComponent,
    CreateRequestComponent,
    ClientMatterComponent,
    NdComboBoxComponent,
    IconComponent,
    BulkTeamEditComponent,
    TeamAppsComponent,
    CreatePlannerTabComponent
  ],
  imports: [
    BrowserModule,
    RouteConfigModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      autoDismiss: true,
      extendedTimeOut: 0,
    }),
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgbModule,
    NgxElectronModule
  ],
  providers: [
    NgbActiveModal,
    NetDocumentViewerService,
    HelperService,
    LogService,
    TeamsService,
    GlobalService,
    SpService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterseptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
