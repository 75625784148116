import { Component, NgZone, EventEmitter } from '@angular/core';
import { ElectronService } from 'ngx-electron';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { GlobalService } from './services/global.service';
import { LogService } from './services/log.service';
import { NetDocumentViewerService } from './services/netdocument-viewer';
import { ToasterMessageService } from './services/toaster-message.service';
import { NetdocshareLocalstorageService } from './services/netdocshare-localstorage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // title = 'netDocShare Sync Admin';  
  title = 'netDocShare OneDrive Sync';
  constructor(
    public electron: ElectronService,
    public ndvs: NetDocumentViewerService,
    private zone: NgZone,
    public tms: ToasterMessageService,
    public logService: LogService,
    public global: GlobalService,
    public toaster: ToastrService,
    public netDocLocalStorage: NetdocshareLocalstorageService
  ) {
    let NDUser = this.netDocLocalStorage.ndInfo;
    let NDSLicense = this.netDocLocalStorage.licenseInfo;
    this.ndvs.currentUser = (NDUser != undefined && NDUser != null && NDUser != "") ? NDUser : {}
    this.ndvs.currentUserLicense = (NDSLicense != undefined && NDSLicense != null && NDSLicense != "") ? NDSLicense : {}

    this.global.haveSyncAddOn = this.ndvs.currentUser.isNDSync;
    this.global.haveTeamsAppAddOn = this.ndvs.currentUser.isTeamsApp;
    this.global.isGlobalAdmin = (this.ndvs.currentUser.role == "globalAdmin" || this.ndvs.currentUser.role == "syncAdmin") ? true : false;

    //console.log(this.ndvs.currentUserLicense);
    //console.log(this.ndvs.currentUser)

    window.addEventListener("message", (data: any) => {
      //console.log("checkTeamsProvisioning========message===", data)

      this.global.loadingSyncIFrame = true;
      let allowedOrgins = ["http://localhost:4200", "http://localhost:4400", "http://localhost:4500", "https://syncbeta.netdocshare.com", "https://sync.netdocshare.com", "https://services.klobot.ai", "https://syncprev.netdocshare.com"];
      //console.log(data);
      this.global.loadingSyncIFrame = false;
      if (allowedOrgins.indexOf(data.origin.toLowerCase()) >= 0) {
        //window.postMessage("setting-update",'*');
        if (data.data == "checkTeamsProvisioning") {
          let container: any = document.getElementById('SyncContainerFrame');
          let targetWindow = container.contentWindow;
          //console.log("checkTeamsProvisioning========targetWindow===", targetWindow)
          let selectedTeamsData: any = { connectionId: this.global.connectionData._id, teamId: this.global.selectedGroup.Id, teamName: this.global.selectedGroup.DisplayName, channelId: (this.global.selectedChannel.Id != undefined && this.global.syncFrom !== "group") ? this.global.selectedChannel.Id : "", channelName: (this.global.selectedChannel.DisplayName && this.global.syncFrom !== "group") ? this.global.selectedChannel.DisplayName : "", folderId: "", folderName: "", connectionType: 'online', tabId: (this.global.selectedTab.Id != undefined && this.global.selectedTab.Id != null && this.global.syncFrom == "tab") ? this.global.selectedTab.configuration.entityId : "" }

          let appType = "provisioning";
          let spData: any = {};
          //console.log("data.data.===", { app: "teamsProvisioning", netDocsBaseUrl: localStorage.getItem("netDocsBaseUrl"), access_token: localStorage.getItem("access_token"), license_key: localStorage.getItem('license_key'), currentUser: localStorage.getItem("curentUser"), teamsData: selectedTeamsData }, data.origin)
          this.global.loadingSyncIFrame = false;
          /* targetWindow.postMessage({ app: "teamsProvisioning", appType: appType, netDocsBaseUrl: localStorage.getItem("netDocsBaseUrl"), access_token: localStorage.getItem("access_token"), license_key: localStorage.getItem('license_key'), currentUser: localStorage.getItem("curentUser"), teamsData: selectedTeamsData, sharePointData: spData }, data.origin) */
          targetWindow.postMessage({ app: "teamsProvisioning", appType: appType, ndInfo: btoa(JSON.stringify(this.netDocLocalStorage.ndInfo)), licenseInfo: btoa(JSON.stringify(this.netDocLocalStorage.licenseInfo)), teamsData: selectedTeamsData, syncTypes: this.netDocLocalStorage?.licenseInfo?.syncTypes }, data.origin)
        }
        //console.log("data.data.===", data.data);
        var heightTabConfig = data.data.height;
        this.global.heightTabConfig.emit({ height: heightTabConfig })
        //console.log("PT::checkTeamsProvisioning::data.origin::", data.data)
        if (data.data.type != undefined && data.data.type == "closeSync") {
          this.global.loadingSyncIFrame = false;
          if (data.status == "updated" && this.global.syncFrom == "tab") {
            //console.log("data.data====", data)
          }
          //console.log("this.global.syncCreated=======data.data===data.data.data====", data.data, data.data.data)
          if (data.data != undefined && data.data.data != undefined) {
            this.toaster.success((data.data.status == 'added') ? "Sync job created" : "Sync job updated", "Success", { extendedTimeOut: 10 });
            //this.global.selectedChannel.isSyncScheduled = true;
            //this.global.selectedGroup.syncId = data.data.data;
            this.global.syncCreated.emit({ syncId: data.data.data, from: 'channel', status: data.data.status })
          } else {
            console.log("this.global.syncCreated=======this.global.syncFrom====", this.global.syncFrom)
            if (data.data.status == "deleted") {
              if (this.global.syncFrom == "tab") {
                this.global.syncCreated.emit({ from: 'tab', status: data.data.status, data: data.data })
              } else if (this.global.syncFrom == "channel") {
                this.global.syncCreated.emit({ from: 'channel', status: data.data.status })
              } else {
                this.global.syncCreated.emit({ from: 'group', status: data.data.status })
              }
            } else {
              this.global.syncCreated.emit({ from: 'channel', status: data.data.status })
            }
          }
          this.ndvs.showSyncPopup = false;
        } else if (data.data.type != undefined && data.data.type == "401") {
          this.ndvs.logOut();
        }
      }
      //this.ndvs.getUserJobsCount(); // to check sync count
    })

    this.logService.logInitiate();
    if (this.electron.isElectronApp) {
      let remote = this.electron.remote;
      this.createAppMenu();
    }
  }


  createAppMenu() {
    if (this.electron.isElectronApp) {
      let remote = this.electron.remote;
      var Menu = remote.Menu;
      var App = remote.app;

      let menu = new Menu();
      let tpl: any = [
        {
          label: 'View',
          submenu: [
            // {
            //   label: 'Server Configuration',
            //   click: () => {
            //     this.zone.run(()=>{this.nav.navigateByUrl("/config")});
            //   }
            // },
            // {
            //   label: 'Source Analysis',
            //   click: () => {
            //     this.zone.run(()=>{this.nav.navigateByUrl("/analysis")});
            //   }
            // },
            // {
            //   label: 'Copy',
            //   click: () => {
            //     this.zone.run(()=>{this.nav.navigateByUrl("/copy")});
            //   }
            // },
            // { type: 'separator' },
            // {
            //   label: 'Developer tools',
            //   click: () => {
            //     remote.getCurrentWindow().webContents.toggleDevTools();
            //   }
            // },
            { type: 'separator' },
            {
              label: 'Exit',
              click: () => { App.quit(); }
            }
          ]
        }
      ];
      if (!environment.production) {
        tpl[0].submenu.push({ type: 'separator' });
        tpl[0].submenu.push({ label: 'Developer tools', click: () => { remote.getCurrentWindow().webContents.toggleDevTools(); } });
      }
      menu = Menu.buildFromTemplate(tpl);
      Menu.setApplicationMenu(menu);
      remote.getCurrentWindow().maximize();
    }
  }

}
