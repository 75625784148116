import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class ServerRequestsService {

  constructor(private _http: HttpClient, private logService: LogService) { }

  public get(url) {
    let responseOut: EventEmitter<any> = new EventEmitter<any>();
    let requestURL = environment.serviceUrl + url;
    this._http.get(requestURL).subscribe((res: any) => {
      if (res.result == "success") {
        responseOut.emit({ status: 'success', data: (res.syncDefinitions == undefined) ? res.data : res.syncDefinitions, totalSyncJobs: res.totalSyncJobs, totalSPRecords: res.totalSPRecords, totalODRecords: res.totalODRecords, totalUserJobs: res.totalUserJobs })
      } else {
        responseOut.emit({ status: 'failed', message: "" })
      }
    }, (error) => {
      responseOut.emit({ status: 'failed', message: "" })
    })
    return responseOut;
  }

  public delete(url) {
    let responseOut: EventEmitter<any> = new EventEmitter<any>();
    let requestURL = environment.serviceUrl + url;
    this._http.delete(requestURL).subscribe((res: any) => {
      if (res.result == "success") {
        responseOut.emit({ status: 'success', message: res.message })
      } else {
        responseOut.emit({ status: 'failed', message: res.message })
      }
    }, (error) => {
      responseOut.emit({ status: 'failed', message: "" })
    })
    return responseOut;
  }

  public getSearch(url) {
    let responseOut: EventEmitter<any> = new EventEmitter<any>();
    //kalai
    let requestURL = environment.serviceUrl + url;
    this._http.get(requestURL).subscribe((res: any) => {
      if (res.result == "success") {
        responseOut.emit({ status: 'success', data: res.reqdata })
      } else {
        responseOut.emit({ status: 'failed', message: "" })
      }
    }, (error) => {
      responseOut.emit({ status: 'failed', message: "" })
    })
    return responseOut;
  }


  

}
