import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TeamsService } from 'src/app/services/teams.service';

@Component({
  selector: 'create-planner-tab',
  templateUrl: './create-planner-tab.component.html',
  styleUrls: ['./create-planner-tab.component.scss']
})
export class CreatePlannerTabComponent implements OnInit {
  @Input("isReturnConfig") isReturnConfig: boolean = false;
  @Input("modal") modal: any = {};
  @Input("group") group: any = {};
  @Input("channel") channel: any = {};
  @Input("onTabCreated") onTabCreated: EventEmitter<any>;
  createPlanType: string = "createNew";
  tabName: string = "";
  existingPlans: any = {
    isFetching: true,
    plans: [],
    selected: null
  };
  isCreatingTab: boolean = false;

  constructor(
    public teamsSrvc: TeamsService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    if (this.group?.id) this.getExistingPlans();
    else this.existingPlans.isFetching = false;
  }

  getExistingPlans() {
    this.teamsSrvc.getExistingPlans(this.group.id).subscribe(res => {
      this.existingPlans.plans = res?.data || [];
      this.existingPlans.isFetching = false;
    });
  }

  onCreatePlannerTab() {
    if (this.createPlanType == "createNew" && !this.tabName?.trim()) {
      this.toaster.error("Tab name is empty");
      return;
    }
    if (this.createPlanType == "useExisting" && !this.existingPlans.selected?.id) {
      this.toaster.error("Please select existing plan to create");
      return;
    }
    if (!this.isReturnConfig) {
      this.isCreatingTab = true;
      const requestBody = {
        teamId: this.group.id,
        channelId: this.channel.id,
        planId: this.createPlanType == "useExisting" ? (this.existingPlans.selected?.id || "") : "",
        tabName: this.createPlanType == "createNew" ? this.tabName : ""
      };
      this.teamsSrvc.createPlannerTab(requestBody).subscribe(res => {
        if (res.status == "success") {
          let data = res.data;
          if (!data.teamsApp) data.teamsApp = { displayName: "Planner" };
          data = { ...data, DisplayName: data.displayName, Id: data.id };
          this.onTabCreated.emit(data);
          this.closeModal(data);
          this.toaster.success("Planner tab created successfully");
        } else this.toaster.error("Failed to create planner tab");
        this.isCreatingTab = false;
      });
    } else {
      const plannerData = {
        tabConfig: {
          tabName: this.tabName,
          tabId: "0",
          config: ""
        }
      };
      this.closeModal(plannerData);
    }
  }

  closeModal(data = null) {
    this.modal?.close(data);
  }
}
