<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Apps</h4>
    <span class="close-icon" (click)="closeModal()">&times;</span>
</div>
<div class="modal-body">
    <div class="teams-apps-container">
        <div class="teams-app" *ngFor="let app of apps" [title]="app.title" (click)="onChooseApp(app)">
            <img [src]="app.icon" class="app-icon" />
            <div>{{app.title}}</div>
        </div>
    </div>
</div>

<ng-template #createPlannerTabModal let-modal>
    <create-planner-tab [modal]="modal" [group]="group" [channel]="channel" [onTabCreated]="onTabCreated">
    </create-planner-tab>
</ng-template>