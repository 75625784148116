<div class="ip-container-box-sm border-0 ip-container-holder">
  <div class="part-1 ip-container-box-sm-header p-2 border-bottom d-flex justify-content-between align-items-center">
    <b>Tabs</b>
    <div class="col-auto p-0">
      <button *ngIf="global.haveTeamsAppAddOn" class="btn btn-sm im-primary-outline-btn waves-effect mr-2" type="button"
        [disabled]="(connectionData.isConnected==undefined || !connectionData.isConnected || channelData.Id==undefined || !global.isNDSAppInstalled)"
        (click)="onShowApps(appsModal)">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-6 -6)">
            <path id="Path_787" data-name="Path 787" d="M18,7.5v21" fill="none" stroke="#296191" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="3" />
            <path id="Path_788" data-name="Path 788" d="M7.5,18h21" fill="none" stroke="#296191" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="3" />
          </g>
        </svg>
        Tab
      </button>
    </div>
  </div>
  <div class="part-3 col-12">
    <div class="scroll-content-holder">
      <div *ngIf="tabsLoader" class="section-loader">
        <img src="assets/images/Spinner.gif">
      </div>
      <div *ngIf="isTeamsRequestFailed">Tabs data fetching error</div>
      <div *ngIf="teamsTabsRequest!=null && !tabsLoader && tabs.length==0">Tabs not found</div>
      <ul class="list-group list-border-0 team-channel-list">
        <li *ngFor="let tab of tabs; let tabIndex=index"
          class="list-group-item d-flex justify-content-between align-items-center teams-channels-list-show-actions">
          <div class="col-12 p-0 d-flex align-items-center teams-channels-name" title="{{tab.DisplayName}}"><img
              src="assets/images/icons/files-tab-icon.svg" class="default-icon-sm">
            <div class="name" (click)="onSelectTab(tab)">
              <div class="title">{{tab.DisplayName}}</div>
            </div>
          </div>


          <div class="col-auto p-0 d-flex">
            <div class="elipse-action" *ngIf="global.isGlobalAdmin">
              <div ngbDropdown placement="bottom-right" class="d-inline-block">
                <div ngbDropdownMenu><button
                    *ngIf="tab.teamsApp.displayName.toLowerCase()!='wiki' && tab.teamsApp.displayName.toLowerCase()!='planner' && global.haveTeamsAppAddOn"
                    ngbDropdownItem (click)="onEditTab(modalTab,tab,tabIndex)">
                    Edit
                  </button><button ngbDropdownItem (click)="onDeleteTab(modalDelete,tab,tabIndex)">
                    Delete
                  </button>
                </div><img ngbDropdownToggle src="./../../../assets/images/icons/ellipse-hover-icon.svg" width="25px">
              </div>
            </div>
            <button
              *ngIf="tab.configuration.entityId && (!isTabSync(tab) && (isChannelSync(tab)||isTeamSync(tab))&& global.haveSyncAddOn)"
              class="btn btn-icon im-white-outline-btn ml-2 waves-effect" type="button" id="btn" [disabled]="true"
              title="{{isTeamSync(tab)?'This tab have team level sync job.':'This tab have channel level sync job.'}}"><img
                src="assets/images/icons/netdocshare-logo.svg">
            </button>
            <!-- <button
            *ngIf="tab.configuration.entityId && (!isTabSync(tab) && !isChannelSync(tab)&&!isTeamSync(tab)&& global.haveSyncAddOn)"
            class="btn btn-icon im-primary-btn sync-btn waves-effect ml-2 me-2"
            id="btn"
            [disabled]="global.maxSyncJobCreated"
            (click)="onConfigureSync($event, tab,modalInfo )"
            title="{{global.maxSyncJobCreated?'Maximum Sync Job Created':'Create Sync job'}}"
         ><img src="assets/images/icons/sync-white-icon.svg">
          </button> -->
            <button
              *ngIf="tab.configuration.entityId && (isTabSync(tab)&& global.haveSyncAddOn && getNetDocumentsCabinetId(tab))"
              class="btn btn-icon im-white-outline-btn ml-2 waves-effect cursor-default" id="btn-disabled"
              (click)="onConfigureSync($event, tab,modalInfo )" title="Tab synced."><img
                src="assets/images/icons/netdocshare-logo.svg">
            </button>
            <!-- <button
            *ngIf="tab.configuration.entityId && (isTabSync(tab)&& global.haveSyncAddOn && !getNetDocumentsCabinetId(tab))"
            class="btn btn-icon im-white-outline-btn ml-2 waves-effect cursor-default"
            (click)="onConfigureSync($event, tab,modalInfo )"
            title="Map sync location"><img src="assets/images/icons/info-icon.svg">
          </button> -->
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #modalTab>
  <create-tab [tabsList]="tabs" [tabDetail]="selectedTab" (change)="onAddTab($event)"></create-tab>
</ng-template>

<ng-template #modalDelete let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
  </div>
  <div class="modal-body">Are you sure to delete this tab?</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
      No
    </button>
    <button type="button" class="btn im-primary-btn" (click)="onDeleteConfirm(modal)">
      Yes
      <img *ngIf="isTabDeleteLoader" src="assets/images/Spinner.gif" class="search-button-img">
    </button>
  </div>
</ng-template>

<ng-template #modalInfo>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cannot create channel level sync job.</h4>
  </div>
  <div class="modal-body">
    This Channel has a Team level sync job. To create a Channel level sync job, remove Team level sync job and then
    create a Channel level sync job.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #appsModal let-modal>
  <team-apps [modal]="modal" [group]="groupData" [channel]="channelData" [onTabCreated]="onTabCreated"
    (onNetDocShareAppChoosed)="onShowCreateTab(modalTab)"></team-apps>
</ng-template>