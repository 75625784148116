<div class="ip-container-box-sm ip-container-holder">
  <div class="part-1 ip-container-box-sm-header p-2 border-bottom d-flex justify-content-between align-items-center">
    <b>Your Teams</b>
    <div class="col-auto p-0">
      <label class="dropdown-icon">
        <div class="dropdown-icon-button">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAAAsTAAALEwEAmpwYAAACm0lEQVR4nO3cP2gUQRgF8I1/QQTBQtDSwsrCzkawsFFu5yUpVksrr7PU3EyKLdXOVitLTaOYb05FIYWNhWBhZWGpYCEIIvh/5YhnJORUvAvz3t48+Or98W5uttqvKHJycnJyJhZ467lgCyyVIsRmoykY43rLZxHs+2BcsG5BEJkCSx/n4O3LEOh8/Da7GM+kdkkUWPr+CQT7uB7pgn0uQzyV0kZfIHp21Hl7PwoKHz+Ui/1jyXzMBXb88mHn7e3I8tZO4rvZYEdSGGkL7PTsoPP2+m/lrd2J9mauFw+ldlNkvn6wzwV78a/l/TYvceHOgWKaUy083ON8fPYf5TU/53lV399bTGPK+u4ueHs8RnnN6p0Yn1T1yu5imlLVSzvgrT9uefg19ujk+f7OqXiJVNXSVoR4a3LlxWGJt4/XK9taXmAz44Jdn3x5cTg3Bs9obYEIdmUTy2tW70S72uITqA0FqUsGClKXLpQ1yAXmApMG+QS2s0CQumSgIHXJQEHqkoGC1CUDBalLBgpSlwwUpC4ZKEhdMlCQumSgIHXJQEHqkoGC1CUDBalLBgpSlwwUpC4ZKEhdMlCQumSgIHXJQEHqkoGC1CUDBalLBgpSlwwUpC4ZKEhdMlCQumSgIHXJQEHqkoGC1CUDBalLBgpSlwwUpC4ZKEhdMkEucLzkAsdMLnBaCgQplNUlAwWpKzkUPl4e9cxJjQvx0mb5CX7pZgY+XlP9DJfir1LX9Rbn7abih+A0d023+3S74ioCqpR5Gcb4yetYEi8Emg/39k/CIJ/OYCVViK/kVlIh0UtEdSkadYEKa/noC/zjYkgfP6VeDClR4IarSUP86rydLtgC0gLXL8eFj+cKxoC4wEGcjxcHk9qRk5OTU7QoPwAUkaabdYzjsgAAAABJRU5ErkJggg==">
        </div>
        <input type="checkbox" class="dropdown-icon-input" id="test">

        <ul class="dropdown-icon-menu">
          <li (click)="sortAcending()" class="{{sort=='asc'?'li-disabled':'li-enabled'}}">A-Z</li>
          <li (click)="sortDecending()" class="{{sort=='desc'?'li-disabled':'li-enabled'}}">Z-A</li>
          <li (click)="clearSorting()" *ngIf="sort!==''" class="sort-clear">Clear</li>
        </ul>
      </label>

      <button class="btn btn-sm im-primary-outline-btn waves-effect ml-2" type="button"
        (click)="onShowCreateOption(createOption)" (keydown)="disableKeyPress($event)"
        [disabled]="(connectionData.isConnected==undefined && !connectionData.isConnected) || groupsLoader">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-6 -6)">
            <path id="Path_787" data-name="Path 787" d="M18,7.5v21" fill="none" stroke="#296191" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="3" />
            <path id="Path_788" data-name="Path 788" d="M7.5,18h21" fill="none" stroke="#296191" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="3" />
          </g>
        </svg>
        Teams
      </button>
    </div>
  </div>

  <div class="part-2 col-12 p-2">
    <div class="search-box">
      <img class="search-icon"
        src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwMDAwMCIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K">

      <input placeholder="Search Teams" type="search" class="search-input-text form-control"
        [disabled]="(connectionData.isConnected==undefined || !connectionData.isConnected ||loadTeam )"
        (ngModelChange)="onChangeSearch($event)" [(ngModel)]="teamsSearch">

      <img class="clear-icon"
        src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxLjk3NiA1MS45NzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxLjk3NiA1MS45NzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPGc+Cgk8cGF0aCBkPSJNNDQuMzczLDcuNjAzYy0xMC4xMzctMTAuMTM3LTI2LjYzMi0xMC4xMzgtMzYuNzcsMGMtMTAuMTM4LDEwLjEzOC0xMC4xMzcsMjYuNjMyLDAsMzYuNzdzMjYuNjMyLDEwLjEzOCwzNi43NywwICAgQzU0LjUxLDM0LjIzNSw1NC41MSwxNy43NCw0NC4zNzMsNy42MDN6IE0zNi4yNDEsMzYuMjQxYy0wLjc4MSwwLjc4MS0yLjA0NywwLjc4MS0yLjgyOCwwbC03LjQyNS03LjQyNWwtNy43NzgsNy43NzggICBjLTAuNzgxLDAuNzgxLTIuMDQ3LDAuNzgxLTIuODI4LDBjLTAuNzgxLTAuNzgxLTAuNzgxLTIuMDQ3LDAtMi44MjhsNy43NzgtNy43NzhsLTcuNDI1LTcuNDI1Yy0wLjc4MS0wLjc4MS0wLjc4MS0yLjA0OCwwLTIuODI4ICAgYzAuNzgxLTAuNzgxLDIuMDQ3LTAuNzgxLDIuODI4LDBsNy40MjUsNy40MjVsNy4wNzEtNy4wNzFjMC43ODEtMC43ODEsMi4wNDctMC43ODEsMi44MjgsMGMwLjc4MSwwLjc4MSwwLjc4MSwyLjA0NywwLDIuODI4ICAgbC03LjA3MSw3LjA3MWw3LjQyNSw3LjQyNUMzNy4wMjIsMzQuMTk0LDM3LjAyMiwzNS40NiwzNi4yNDEsMzYuMjQxeiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=">
    </div>
  </div>
  <div class="part-3 col-12 middle-list-height">
    <div class="scroll-content-holder">
      <div *ngIf="isTeamsRequestFailed">Teams groups data fetching error</div>
      <div *ngIf="teamsGroupRequest!=null && !groupsLoader && teamsGroups.length==0">Teams groups not found</div>
      <ul *ngIf="teamsGroups.length>0" class="list-group list-border-0 team-channel-list">
        <li
          class="{{checkSelection(group)?'list-group-item  selected-group teams-channels-list-show-actions':'list-group-item teams-channels-list-show-actions'}}"
          *ngFor="let group of teamsGroups">
          <div title="{{group.DisplayName}}" (click)="onSelectTeam(group)"
            class="col p-0 d-flex align-items-center teams-channels-name">
            <div class="teams-name-highlight">
              {{group.DisplayName.charAt(0)}}{{group.DisplayName.charAt(1)}}
            </div>
            <div class="name">
              <div class="title">{{group.DisplayName}}</div>
            </div>
          </div>
          <div class="col-auto p-0 d-flex">

            <div class="elipse-action" *ngIf="global.isGlobalAdmin">
              <div ngbDropdown placement="bottom-right" class="d-inline-block">
                <img ngbDropdownToggle src="./../../../assets/images/icons/ellipse-hover-icon.svg" width="25px">
                <div ngbDropdownMenu>
                  <button *ngIf="!group.isArchived" ngbDropdownItem [disabled]="group.isArchiveProcessing"
                    (click)="onArchiveTeam(group)">
                    Archive
                  </button>
                  <button *ngIf="group.isArchived" ngbDropdownItem [disabled]="group.isUnArchiveProcessing"
                    (click)="onUnArchiveTeam(group)">
                    Unarchive
                  </button>
                  <button ngbDropdownItem (click)="onEditTeam(group, modalEditTeam)">
                    Edit
                  </button>
                  <button ngbDropdownItem (click)="onDeleteTeam(group, modalDeleteTeam)">
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div class="archive" *ngIf="group.isArchived">
              <svg fill="currentColor" aria-hidden="true" width="1em" height="1em" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.5 10a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3ZM2 4.75C2 3.78 2.78 3 3.75 3h12.5c.97 0 1.75.78 1.75 1.75v1.5c0 .7-.4 1.3-1 1.58V14a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7.83c-.6-.28-1-.88-1-1.58v-1.5ZM3.75 4a.75.75 0 0 0-.75.75v1.5c0 .41.34.75.75.75h12.5c.41 0 .75-.34.75-.75v-1.5a.75.75 0 0 0-.75-.75H3.75ZM4 8v6c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2V8H4Z"
                  fill="currentColor">
                </path>
              </svg>
            </div>
            <button title="Create Template" class="btn btn-icon im-primary-btn waves-effect ml-2 me-2" type="button"
              (click)="toTemplate(group, modalTab)"><img src="assets/images/note_add_white_24dp.svg">
            </button><button title="Clone Team" class="btn btn-icon im-primary-btn waves-effect ml-2 me-2" type="button"
              (click)="onDuplicate(group, modalTab)"><img src="assets/images/file_copy_white_24dp.svg">
            </button><button
              *ngIf="!group.teamSyncNotMapped&&((group.isSyncScheduled==undefined || group.isSyncScheduled==null || !group.isSyncScheduled)) && global.haveSyncAddOn&&!syncDisable(group)"
              class="btn btn-icon im-primary-btn sync-btn waves-effect ml-2 me-2" type="button"
              title="{{global.maxSyncJobCreated?'Maximum Sync Job Created':'Create Sync Job'}}"
              [disabled]="syncDisable(group)||global.maxSyncJobCreated" (click)="onConfigureSync($event, group)"><img
                src="assets/images/icons/sync-white-icon.svg">
            </button><button
              *ngIf="!group.teamSyncNotMapped&&((group.isSyncScheduled==undefined || group.isSyncScheduled==null || !group.isSyncScheduled)) && global.haveSyncAddOn&&syncDisable(group)"
              class="btn btn-icon im-white-outline-btn sync-btn waves-effect ml-2 me-2" type="button"
              title="{{'This team have channel level sync job.'}}"
              [disabled]="syncDisable(group)||global.maxSyncJobCreated" (click)="onConfigureSync($event, group)"><img
                src="assets/images/icons/netdocshare-logo.svg">
            </button><button
              *ngIf="!group.teamSyncNotMapped&&group.isSyncScheduled && syncDisable(group) && global.haveSyncAddOn"
              class="btn btn-icon im-white-outline-btn sync-btn waves-effect ml-2 me-2" type="button"
              title="{{'This team have channel level sync job.'}}" [disabled]="true||global.maxSyncJobCreated"
              (click)="onConfigureSync($event, group, modalInfo)"><img src="assets/images/icons/netdocshare-logo.svg">
              <!-- <img src="assets/images/icons/sync-white-icon.svg"> -->
            </button><button
              *ngIf="!group.teamSyncNotMapped&&group.isSyncScheduled && getNetDocsCabinet(group)&&!syncDisable(group) && global.haveSyncAddOn"
              class="btn btn-icon im-white-outline-btn ml-2 waves-effect" type="button" title="Sync Job" id="btn"
              [disabled]="syncDisable(group)" (click)="onConfigureSync($event, group)"><img
                src="assets/images/icons/netdocshare-logo.svg">
            </button><button
              *ngIf="group.teamSyncNotMapped||(group.isSyncScheduled &&!getNetDocsCabinet(group)&& !syncDisable(group) && global.haveSyncAddOn)"
              class="btn btn-icon im-white-outline-btn ml-2 waves-effect" type="button" title="Map sync location"
              [disabled]="syncDisable(group)" (click)="onConfigureSync($event, group)"><img
                src="assets/images/icons/info-icon.svg">
            </button>
          </div>
        </li>
      </ul>
      <div *ngIf="groupsLoader" class="section-loader">
        <img src="assets/images/Spinner.gif">
      </div>
      <div class="d-flex justify-content-center">
        <button *ngIf="nextPageRequest&&!groupsLoader&&teamsGroups.length>0" class="btn btn-sm" type="button"
          (click)="onFetchMore()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-6 -6)">
              <path id="Path_787" data-name="Path 787" d="M18,7.5v21" fill="none" stroke="#296191"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
              <path id="Path_788" data-name="Path 788" d="M7.5,18h21" fill="none" stroke="#296191"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            </g>
          </svg>
          More
        </button>
      </div>
    </div>
  </div>

</div>

<ng-template #createOption>
  <create-teams-options (onTypeSelection)="selectedType($event, modalTab)"></create-teams-options>
</ng-template>

<ng-template #modalTab>
  <app-team-creation-steps [selectedKind]="selectedKind" [duplicateGroupInfo]="seletedGroup"
    (createGroup)="createGroup($event)" [teamsGroups]="teamsGroups">
  </app-team-creation-steps>
</ng-template>

<ng-template #modalInfo>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cannot create team level sync job.</h4>
  </div>
  <div class="modal-body">
    This Team has a Channel level sync job. To create a Team level sync job, remove all Channel level sync jobs and then
    create a Team level sync job.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #modalDeleteTeam>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete the {{this.global.selectedGroup.displayName}} team</h4>
  </div>
  <div class="modal-body">
    Are you sure you want to delete the {{this.global.selectedGroup.displayName}} team? All hosted channels,
    conversations, files, and the Microsoft 365 group for this team will be deleted. You'll lose access to any shared
    channels that have been shared with your team.
    <br>
    <br>
    <div class="acknowledge">
      <input type="checkbox" (click)="agreeDelete($event)" id="toDelete">
      <label class="form-check-label" for="toDelete">
        {{" I understand that everything will be deleted."}}
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()" [disabled]="startDeleting">
      Cancel
    </button>
    <button type="button" class="btn btn-blue" (click)="deleteTeam()" [disabled]="deleteDisable()">
      Delete team
      <img class="loader-image" *ngIf="startDeleting" src="assets/images/Spinner.gif">
    </button>
  </div>
</ng-template>

<ng-template #modalEditTeam>
  <app-edit-team (updateGroup)="onTeamUpdate($event)"></app-edit-team>
</ng-template>