import { Component, OnInit, AfterViewInit, ViewChild, Output, ElementRef, EventEmitter, NgZone, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { TeamsService } from 'src/app/services/teams.service';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { NetDocumentViewerService } from 'src/app/services/netdocument-viewer';
import { LogService } from 'src/app/services/log.service';
import { HelperService } from 'src/app/services/helper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { NetdocshareLocalstorageService } from 'src/app/services/netdocshare-localstorage.service';
@Component({
  selector: 'create-channel',
  templateUrl: './create-channel.component.html',
  styleUrls: ['./create-channel.component.scss']
})
export class CreateChannelComponent implements OnInit, AfterViewInit {


  @Output() syncChannel: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("inputChannelName") inputChannelName: ElementRef;
  @Input("requestFromBulkEdit") public requestFromBulkEdit: boolean = false;
  @Input("channelDetail") channelDetail: any = {}
  @Output() onChangeChannelData: EventEmitter<any> = new EventEmitter<any>();
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  dropDownForm: FormGroup;

  public formChannel: FormGroup;
  public channelCreateLoader: boolean = false;
  public isRequestError: boolean = false;
  public isRequestFailed: boolean = false;
  public isChannelExist: boolean = false;
  public message: string = "";
  public syncCheck: boolean = false;

  //Tab Creation
  public tabCreationCheck: boolean = false;
  public tabs: any = [];
  public selectedTab: any = null;
  public isTabExist: boolean = false;
  public configSettingUrl: SafeUrl;
  public tabsList: any = {};
  public tabDetail: any = null;
  public configSetting: any = null;
  public formTab: FormGroup;
  public tabConfigSetting: any = {};
  public tabConfiguration: any = {};
  public configSettingListener: any;
  public tabSetting: boolean = false;

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfigChange: EventEmitter<any> = new EventEmitter<any>();


  public ndSiteUrl: string = this.ndvs.getUserWebUrl();
  public isNDSUserAccess: boolean = true;

  public toolTip: any = {
    syncType: "Select any one Sync Type",
    ndCabinet: "Please choose the cabinet where you would like to sync your content",
    syncLocation: "Select Sync Location",
    tabCreation: "Create a netDocShare tab"
  }


  public syncTypes: any = [
    { value: "bidirectionalTeam", text: "Bidirectional (Teams-NetDocuments)" },
    { value: "netDocumentsUniTeam", text: "NetDocuments to Teams" },
    { value: "teamUni", text: "Teams to NetDocuments" }
  ]


  public fileIcons: any = {
    ndfld: "folder_nd.svg",
    ndflt: "filter_nd.svg",
    ndsq: "savedSearch_nd.svg",
    ndcs: "collaborationSpace_nd.svg",
    ss: "sharedspace_nd.svg",
    pdf: "pdf_nd.svg",
    doc: "word_nd.svg",
    docx: "word_nd.svg",
    pptx: "powerPoint_nd.svg",
    ppt: "powerPoint_nd.svg",
    xlsx: "excel_nd.svg",
    xls: "excel_nd.svg",
    csv: "excel_nd.svg",
    jpg: "image_nd.svg",
    jpeg: "image_nd.svg",
    png: "image_nd.svg",
    svg: "image_nd.svg",
    txt: "blankDocument_nd.svg",
    url: "webLink_nd.svg",
    zip: "blankDocument_nd.svg",
    eml: "email_nd.svg",
    gen: "genericfile_icon.svg",
    file: "file_icon.svg",
    html: "webLink_nd.svg",
    htm: "webLink_nd.svg",
    css: "blankDocument_nd.svg",
    js: "blankDocument_nd.svg",
    one: "onenote_nd.svg",
    onetoc: "onenote_nd.svg",
    onetoc2: "onenote_nd.svg",
  }


  public syncType: string = "teamUni";
  public form: FormGroup;

  public isCabinetLoader: boolean = false;
  public cabinets: any = [];
  public selectedCabinet: any = { name: "" };
  public showLoader: boolean = true;
  public isFolerLoading: boolean = false;
  private _isNDLoading: boolean = false;
  public workSpaceContents: any = [];
  public NDSContainerContents: any = [];
  public cabinetFolders: any = [];
  public workSpaces: any = [];
  public selectedWorkSpace: any = {};
  public selectCabinetFolder: any = {};
  public isCabinetFolderLoader: boolean = false;
  public isWorkspaceLoader: boolean = false;
  public breadcurmbClicked: boolean = false;
  public cabinetSearch: string = "";
  public isDisabledSave: boolean = true;
  public isEditMode: boolean = false;
  public isOnpermRestriction: boolean = true;

  public ndNextRecord: string = null;
  public ndFilesNextRecord: string = null;
  public ndContentType: string = "folders";
  public isFloderScrollEnded: boolean = false;
  public isFileScrollEnded: boolean = false;
  public executedPagingTokens: any = [];
  public serviceResuest: any = null;
  private NDSContentsServiceResuest: any = null;
  public isTeamsLoading: boolean = false;
  public isODLoading: boolean = false;
  public folderCreationError = "";
  public folderCreationErrorMessage = "";


  get isNDLoading(): boolean {
    return this._isNDLoading;
  }

  set isNDLoading(value: boolean) {
    this._isNDLoading = value;
    if (!value) {
      this.onSaveEnable();
    }
  }

  public ndCollumns: any = [];
  public spCollumns: any = [];
  public newColRecord: any = { SPCol: '', SPColTitle: "", spColDataType: '', NDCol: '', NDColId: null, NDColDataType: '', dataDirection: this.syncType }
  public newSPColData: any = {};

  public metaDataColPro: any = [];
  public isDoneDisabled: boolean = true;

  public newPrivateChannelMembers: any = [];
  public removedMembers: any = [];

  public isNDSSearchButtonDisabled: boolean = false;
  public NDSSearchString: string = "";
  public isNDSSearchResults: boolean = false;
  private NDSExecutedPagingTokens: any = [];
  public isNDSFolderCreating: boolean = false;
  public isNDSDuplicateFolder: boolean = false;
  public NDSFolderName: string = "";
  public error_message: string = '';
  public showFolderInput: boolean = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private teamsService: TeamsService,
    public global: GlobalService,
    private toaster: ToastrService, private fb: FormBuilder,
    private logService: LogService,
    public ndvs: NetDocumentViewerService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private ngZone: NgZone,
    public netDocLocalStorage: NetdocshareLocalstorageService
  ) {
    this.listenConfigSetting();
    this.configSettingUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.configSettingUrl);
    this.formTab = this.formBuilder.group({
      tabName: ["", Validators.required],
    })
  }

  ngOnInit(): void {
    this.setFormData();
    let syncTypes = this.netDocLocalStorage?.licenseInfo?.syncTypes;
    this.syncTypes = syncTypes.filter((element) => element.text.includes("Teams"));
    this.isNDSSearchResults = false;
    this.dropdownSettings = {
      idField: 'id',
      textField: 'name',
      /* allowSearchFilter: true */
    };
    this.selectedItems = [
    ];
    this.dropDownForm = this.fb.group({
      myItems: [this.selectedItems]
    });

    this.form = new FormGroup(
      {
        optionSync: new FormControl(this.syncType),
        selectedCabinetName: new FormControl(this.selectedCabinet.name),
        cabinetSearch: new FormControl(this.cabinetSearch),
        newFolderName: new FormControl(this.folderCreationError),
        NDSFolderName: new FormControl(this.NDSFolderName)
      }
    );
    this.form.valueChanges.subscribe((val) => { /* //console.log(val) */ })

    this.formChannel.valueChanges.subscribe((values) => { if (values.optionPrivacy == "standard") this.newPrivateChannelMembers = [] })


    this.ndvs.setSyncSetting();
    this.ndvs.syncJobSetting.syncType = this.syncType;
    let JobId = '';//this.activatedRoute.snapshot.params.jobId
    if (JobId != undefined && JobId != null && JobId != '') {
      this.ndvs.pageTitle = "Edit Job"
      this.isEditMode = true;
      if (this.ndvs.GSAccessToken == "") {
        this.waitForToken();
        return;
      }
      this.ndvs.getJobDetails(JobId).subscribe((resData) => {
        if (resData.status == "success" && resData.data != undefined) {
          this.ndvs.syncJobId = JobId;
          this.ndvs.syncJobSetting = resData.data;
          /* this.isOnpermRestriction = (!this.electron.isElectronApp && this.ndvs.syncJobSetting.sharePoint.connectionType != undefined && this.ndvs.syncJobSetting.sharePoint.connectionType == 'onpremises'); */
          this.ndvs.syncJobSetting.applyPermissions = (this.ndvs.syncJobSetting.applyPermissions == undefined || this.ndvs.syncJobSetting.applyPermissions == null) ? false : this.ndvs.syncJobSetting.applyPermissions;
          this.syncType = this.ndvs.syncJobSetting.syncType;
          //this.onChangeFrequency('init');
          this.newColRecord.dataDirection = this.syncType;
          if (this.ndvs.syncJobSetting.netDocuments.sources == undefined) this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
          this.getCabinets(JSON.parse(JSON.stringify(this.ndvs.syncJobSetting.netDocuments)));
        } else {
          this.router.navigateByUrl("/syncjobs");
        }
      })
    } else {
      this.ndvs.pageTitle = "Create Jobs"
      this.isEditMode = false;
      this.isOnpermRestriction = false;
      let lKey = this.ndvs.getUserLicense();//localStorage.getItem('license_key');
      this.ndvs.syncJobSetting.key = (lKey != undefined && lKey != null) ? lKey : '';
      this.ndvs.syncJobSetting.licenseKey = (lKey != undefined && lKey != null) ? lKey : '';
      // this.isSyncTimeLoaded = true;
      this.onSaveEnable();
      this.getCabinets();
    }
    this.logService.updateLogMessage(this.ndvs.pageTitle);
    this.syncType = "";
  }

  ngAfterViewInit() {
    this.inputChannelName.nativeElement.focus();
  }

  public onAllowTabCreation($event) {

  }
  public setConfigChange(data) {
    this.configSetting = data;
  }


  public onSaveEnable() {
    this.isDisabledSave = true;
    if (this.isNDLoading) return;
    switch (this.syncType) {
      case "bidirectionalTeam":
        this.isDisabledSave = (this.ndvs.syncJobSetting.syncFrequencyInSecond >= 30 && this.ndvs.syncJobSetting.netDocuments.cabinetId != "" && (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") && ((this.ndvs.syncJobSetting.netDocuments.selectAll) || ((this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0 || this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0)))) ? false : true;
        //&& this.ndvs.syncJobSetting.teams.connectionId != '' && this.ndvs.syncJobSetting.teams.teamId != '' 
        //&& (this.ndvs.syncJobSetting.teams.sources.folders.length > 0 || this.ndvs.syncJobSetting.teams.sources.files.length > 0 || this.ndvs.syncJobSetting.teams.sources.channels.length > 0)
        // && this.ndvs.syncJobSetting.teams.selectAll
        // CONDITION FOR DESTINATION FOLDER TYPE //
        // FOR SKIP OLD DATA //
        if (!this.isDisabledSave && this.ndvs.syncJobSetting.netDocuments.folderType != undefined && this.ndvs.syncJobSetting.netDocuments.folderType != null && this.ndvs.syncJobSetting.netDocuments.folderType != "") {
          this.isDisabledSave = (this.ndvs.syncJobSetting.netDocuments.folderType == 'ndfld' || this.ndvs.syncJobSetting.netDocuments.folderType == 'ws') ? false : true;
        }
        break;
      case "netDocumentsUniTeam":
        this.isDisabledSave = (this.ndvs.syncJobSetting.syncFrequencyInSecond >= 30 && this.ndvs.syncJobSetting.netDocuments.cabinetId != "" && (this.ndvs.syncJobSetting.netDocuments.selectAll || (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0 || this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0))) ? false : true;
        //&& this.ndvs.syncJobSetting.teams.connectionId != '' && this.ndvs.syncJobSetting.teams.teamId != '' && this.ndvs.syncJobSetting.teams.channelId != ''
        break;
      case "teamUni":
        this.isDisabledSave = (this.ndvs.syncJobSetting.syncFrequencyInSecond >= 30 && this.ndvs.syncJobSetting.netDocuments.cabinetId != "" && (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "")) ? false : true;
        //&& this.ndvs.syncJobSetting.teams.connectionId != '' && (this.ndvs.syncJobSetting.teams.selectAll || (this.ndvs.syncJobSetting.teams.sources.folders.length > 0 || this.ndvs.syncJobSetting.teams.sources.files.length > 0 || this.ndvs.syncJobSetting.teams.sources.channels.length > 0))
        // CONDITION FOR DESTINATION FOLDER TYPE //
        // FOR SKIP OLD DATA //
        if (!this.isDisabledSave && this.ndvs.syncJobSetting.netDocuments.folderType != undefined && this.ndvs.syncJobSetting.netDocuments.folderType != null && this.ndvs.syncJobSetting.netDocuments.folderType != "") {
          this.isDisabledSave = (this.ndvs.syncJobSetting.netDocuments.folderType == 'ndfld' || this.ndvs.syncJobSetting.netDocuments.folderType == 'ws') ? false : true;
        }
        break;
    }
  }

  public checkDisable() {
    //this.onCheckSaveEnable();
    if (this.syncCheck && this.global.haveSyncAddOn && !this.global.isChannelEdit) {
      if (this.syncType != "netDocumentsUniTeam") {
        if (this.syncCheck && this.isDisabledSave) {
          return true;
        }
      } else if (this.syncType == "netDocumentsUniTeam") {
        if (this.syncCheck && this.isDisabledSave) {
          return true;
        }
      }
    }

    if (this.tabCreationCheck && !this.tabSetting) {
      return true;
    }
    //|| this.formChannel.controls["channelDescription"].value.trim() == ""
    return (this.formChannel.controls["channelName"].value.trim() == "") ? true : false;
  }

  public onChangeSyncCheck($event) {
    this.syncCheck = !this.syncCheck;

    if (this.global.maxSyncJobCreated) {
      this.syncCheck = false;
    }
    if (!this.syncCheck) {
      this.folderCreationError = "";
      this.selectedCabinet = { name: "" };// has to be done before onResetRoot();
      this.syncType = "";// has to be done before onResetRoot();
      this.onResetRoot();
      this.formChannel.setValue({
        optionSync: this.syncType,
        selectedCabinetName: this.selectedCabinet.name,
        cabinetSearch: this.cabinetSearch,
        newFolderName: this.folderCreationError,
        NDSFolderName: this.NDSFolderName
      });
    }
  }

  private waitForToken() {
    let counter = setTimeout(() => {
      clearTimeout(counter);
      if (this.ndvs.GSAccessToken != "") {
        this.ngOnInit();
      } else {
        this.waitForToken();
      }
    }, 1000)
  }


  public responsiveHeight(filterSecHt, ndFormNavHt, colMetSecHt) {
    setTimeout(() => {
      let height = filterSecHt + ndFormNavHt + colMetSecHt + 276;
      return "calc(100vh - " + height + "px)";
    }, 500)
  }

  public onChangeSyncType(data) {
    this.ndvs.syncJobSetting.syncType = this.syncType;
    this.newColRecord.dataDirection = this.syncType;
    this.ndvs.syncJobSetting.columnOptions = [];
    this.ndvs.syncJobSetting.syncMetaData = false;
    this.ndvs.syncJobSetting.fileMove = false;
    this.ndvs.syncJobSetting.sharePoint.libraryType = "document";
    this.ndvs.syncJobSetting.sharePoint.transferType = "flatten";
    switch (this.ndvs.syncJobSetting.syncType) {
      case "bidirectionalTeam":
        break;
      case "teamUni":
        //this.ndvs.syncJobSetting.sharePoint = {};
        //this.ndvs.syncJobSetting.oneDrive = {};
        break;
    }
    this.ndvs.onChangeSyncType.emit(this.syncType);
    this.onRefreshNetDocumentContants();
    this.onSaveEnable();
  }

  public onShowBrowser(url) {
    if (this.isNDLoading) return;
    this.ndvs.onShowBrowser(url);
  }

  public onSelectWorkSpace(workspace, dropdown = null, netDocData = null) {
    this.selectedWorkSpace = workspace;
    this.cabinetSearch = "";
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    this.NDSContainerContents = [];
    this.ndvs.syncJobSetting.netDocuments.workspaceId = this.selectedWorkSpace.id;
    this.ndvs.syncJobSetting.netDocuments.workspaceName = this.selectedWorkSpace.name;
    this.ndvs.syncJobSetting.netDocuments.workspaceEnvId = this.selectedWorkSpace.envId;
    if (dropdown != null) dropdown.close();
    this.onResetScrollVars();
    if (netDocData == null || this.ndvs.syncJobSetting.netDocuments.breadCurmb == undefined || this.ndvs.syncJobSetting.netDocuments.breadCurmb.length == 0) {
      this.ndvs.syncJobSetting.netDocuments.breadCurmb = [];
      if (netDocData == null) this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
      this.ndvs.syncJobSetting.netDocuments.folderId = "";
      this.ndvs.syncJobSetting.netDocuments.folderName = "";

      this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, this.ndvs.syncJobSetting.netDocuments.workspaceId);
    } else {
      this.ndvs.syncJobSetting.netDocuments.folderId = this.ndvs.syncJobSetting.netDocuments.breadCurmb[this.ndvs.syncJobSetting.netDocuments.breadCurmb.length - 1].DocId;
      this.ndvs.syncJobSetting.netDocuments.folderName = this.ndvs.syncJobSetting.netDocuments.breadCurmb[this.ndvs.syncJobSetting.netDocuments.breadCurmb.length - 1].Attributes.Name;

      this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, this.ndvs.syncJobSetting.netDocuments.folderId)
      // }    
    }
  }

  public onSelectCabinetFolder(cabFolder) {
    if (this.isNDLoading) return;
    this.selectCabinetFolder = cabFolder;
    this.cabinetSearch = "";
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    this.NDSContainerContents = [];
    this.isFloderScrollEnded = false;
    this.isFileScrollEnded = false;
    this.executedPagingTokens = [];
    this.isFolerLoading = false;
    this.onSelectContent(cabFolder);
    this.onNDSCreateFolderHide();
  }

  public onSelectContent(content) {
    //ndflt: disable - filter
    //ndsq: disable - Saved Search but this is enabled...!! Need to check in sync.
    //ndcs: collabspace
    //ss : Shared Space - No Sync in Root. Only in folder
    //Cabinet : Only folders can be sync to Root. No files copy. - This also false

    //Teams to NetDocs
    //ndflt, ndsq, ndcs - disabled
    //ss - No Sync in Root. Only in folder

    //NetDocs to Teams
    //ndflt, ndsq, ndcs, ss - Enable
    //ndflt, ndsq, ndcs, ss,  - Select file/folder to sync

    //BiDirectional
    //ndflt, ndsq, ndcs - disable
    //ss - No Sync in Root. Only in folder
    if (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0 || this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0 || content.isRestricted) return;
    if (content.Attributes.Ext == "ndfld" || content.Attributes.Ext == "ndflt" || content.Attributes.Ext == "ndsq" || content.Attributes.Ext == "ndcs" || content.Attributes.Ext == "ss") {
      this.workSpaceContents = [];
      this.NDSContainerContents = [];
      this.cabinetSearch = "";
      this.isNDSSearchResults = false;
      let docId = ""
      if (content.Attributes.Ext == "ss") {
        this.ndvs.syncJobSetting.netDocuments.breadCurmb.push({ DocId: content.EnvId, Attributes: { Name: content.Attributes.Name, Ext: content.Attributes.Ext }, EnvId: content.EnvId });
        this.ndvs.syncJobSetting.netDocuments.folderId = content.EnvId;
        docId = content.EnvId;
        this.ndvs.syncJobSetting.netDocuments.folderName = content.Attributes.Name;
      } else {
        this.ndvs.syncJobSetting.netDocuments.breadCurmb.push({ DocId: content.DocId, Attributes: { Name: content.Attributes.Name, Ext: content.Attributes.Ext }, EnvId: content.EnvId });
        this.ndvs.syncJobSetting.netDocuments.folderId = content.DocId;
        docId = content.DocId;
        this.ndvs.syncJobSetting.netDocuments.folderName = content.Attributes.Name;
      }
      this.ndvs.syncJobSetting.netDocuments.folderType = content.Attributes.Ext;
      this.ndvs.syncJobSetting.netDocuments.selectAll = false;
      this.onResetScrollVars();



      this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, docId);
    }
    this.onSaveEnable();
  }

  public onChooseNDContent(event, selectedContent) {
    if (this.isNDLoading || selectedContent.isRestricted) return;
    selectedContent.isChecked = !selectedContent.isChecked;//event.target.checked;
    let contentExt = (selectedContent.extension != undefined && selectedContent.extension != null) ? selectedContent.extension : selectedContent.Attributes.Ext;
    if (selectedContent.isChecked) {
      if (contentExt == "ndws") {
        this.ndvs.syncJobSetting.netDocuments.sources.workspaces.push(selectedContent.id)
      } else if (contentExt == "ndfld" || contentExt == "ndflt" || contentExt == "ndsq" || contentExt == "ndcs" || contentExt == "ss") {
        this.ndvs.syncJobSetting.netDocuments.sources.folders.push((contentExt == "ss") ? selectedContent.EnvId : selectedContent.DocId)
      } else {
        this.ndvs.syncJobSetting.netDocuments.sources.files.push(selectedContent.DocId);
      }
    } else {
      if (contentExt == "ndws" || contentExt == "ndsq" || contentExt == "ndfld" || contentExt == "ndflt" || contentExt == "ndcs" || contentExt == "ss") {
        let foderIndex = this.ndvs.syncJobSetting.netDocuments.sources.folders.findIndex((element => (element == selectedContent.EnvId || element == selectedContent.DocId)));
        if (foderIndex != null && foderIndex >= 0) this.ndvs.syncJobSetting.netDocuments.sources.folders.splice(foderIndex, 1);
      } else {
        let fileIndex = this.ndvs.syncJobSetting.netDocuments.sources.files.findIndex((element => element == selectedContent.DocId))
        if (fileIndex != null && fileIndex >= 0) this.ndvs.syncJobSetting.netDocuments.sources.files.splice(fileIndex, 1);
      }
    }
    this.updateNDSelectAllStatus();
  }

  public onNDSSelectAll() {
    if (this.isNDLoading) return;
    this.ndvs.syncJobSetting.netDocuments.selectAll = !this.ndvs.syncJobSetting.netDocuments.selectAll;
    this.ndvs.syncJobSetting.netDocuments.sources = { workspaces: [], folders: [], files: [] };
    // FOR Container content Selection
    if (this.NDSContainerContents.length > 0) {
      this.NDSContainerContents.forEach(element => {
        element.isChecked = (element.isRestricted) ? false : this.ndvs.syncJobSetting.netDocuments.selectAll;
        if (element.isChecked) {
          let contentExt = (element.extension != undefined && element.extension != null) ? element.extension : element.Attributes.Ext;
          if (contentExt == "ndws" || contentExt == "ndflt" || contentExt == "ndfld" || contentExt == "ndcs" || contentExt == "ndsq" || contentExt == "ss") {
            this.ndvs.syncJobSetting.netDocuments.sources.folders.push((contentExt == "ss") ? element.EnvId : element.DocId);
          } else {
            this.ndvs.syncJobSetting.netDocuments.sources.files.push(element.DocId);
          }
        }
      });
    }
    // FOR Container content Selection
    if (this.workSpaceContents.length > 0) {
      this.workSpaceContents.forEach(element => {
        element.isChecked = (element.isRestricted) ? false : this.ndvs.syncJobSetting.netDocuments.selectAll;
        if (element.isChecked) {
          let contentExt = (element.extension != undefined && element.extension != null) ? element.extension : element.Attributes.Ext;
          if (contentExt == "ndws" || contentExt == "ndflt" || contentExt == "ndfld" || contentExt == "ndcs" || contentExt == "ndsq" || contentExt == "ss") {
            this.ndvs.syncJobSetting.netDocuments.sources.folders.push((contentExt == "ss") ? element.EnvId : element.DocId);
          } else {
            this.ndvs.syncJobSetting.netDocuments.sources.files.push(element.DocId);
          }
        }
      });
    }
    this.onSaveEnable();
  }

  public onClearNDSSelectedItems() {
    if (this.isNDLoading) return;
    this.ndvs.syncJobSetting.netDocuments.sources = { workspaces: [], folders: [], files: [] };
    this.workSpaces.map((x) => { x.isChecked = false });
    this.NDSContainerContents.map((x) => { x.isChecked = false });
    this.workSpaceContents.map((x) => { x.isChecked = false });
    this.cabinetFolders.map((x) => { x.isChecked = false });
    this.onSaveEnable();
  }

  public onRefreshNetDocumentContants() {
    if ((!this.isNDLoading && this.ndvs.syncJobSetting.netDocuments.cabinetId == '') || (this.isNDLoading)) return;
    this.isNDSUserAccess = true
    this.isNDSSearchResults = false;
    this.onResetScrollVars()
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    this.NDSContainerContents = [];
    this.ndvs.syncJobSetting.netDocuments.sources = { workspaces: [], folders: [], files: [] };
    if (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") {
      let contentSource: string = "";
      if ((this.ndvs.syncJobSetting.netDocuments.workspaceId != "" && this.ndvs.syncJobSetting.netDocuments.folderId != "") || (this.ndvs.syncJobSetting.netDocuments.workspaceId == "" && this.ndvs.syncJobSetting.netDocuments.folderId != "")) {
        contentSource = this.ndvs.syncJobSetting.netDocuments.folderId;
      } else {
        contentSource = this.ndvs.syncJobSetting.netDocuments.workspaceId;
      }

      if (this.ndvs.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
    } else {
      if (this.ndvs.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspace(this.ndvs.syncJobSetting.netDocuments.cabinetId, null, this.ndNextRecord);
    }
  }

  private getNDSFolderSearchContents(cabinetId, containerId, skipToken = null) {
    if (this.cabinetSearch.trim() == '' || this.cabinetSearch.length < 3 || this.isNDLoading) return;
    this.isNDLoading = true;
    this.workSpaces = [];
    this.cabinetFolders = [];
    if (skipToken != null) {
      if (this.NDSExecutedPagingTokens.indexOf(skipToken) < 0) {
        this.NDSExecutedPagingTokens.push(skipToken);
      } else {
        this.isNDLoading = false;
        return;
      }
    } else {
      this.NDSExecutedPagingTokens = [];
    }
    if (skipToken == null && !this.isFloderScrollEnded) {
      this.NDSContainerContents = [];
      this.workSpaceContents = [];
    }

    if (this.NDSContentsServiceResuest != null) this.NDSContentsServiceResuest.unsubscribe();
    this.isNDSUserAccess = true;
    this.NDSContentsServiceResuest = this.ndvs.getSearchedFilesAndFolders(cabinetId, containerId, this.NDSSearchString, this.ndContentType, skipToken, this.ndvs.syncJobSetting.netDocuments.folderType, this.ndvs.syncJobSetting.syncType).subscribe((resData) => {
      this.NDSContentsServiceResuest = null;
      if (resData.status == "success" && resData.data != undefined) {
        if (resData.data.Results != undefined && resData.data.Results.length > 0) {
          this.ndNextRecord = (resData.skipToken != null) ? resData.skipToken : ((skipToken != null) ? skipToken : null);
          resData.data.Results.forEach((ConainerElement) => {
            ///// CONTENT RESTRICTION /////
            if (this.syncType != 'netDocumentsUniTeam') {
              ConainerElement.isRestricted = (ConainerElement.Attributes.Ext == "ndflt" || ConainerElement.Attributes.Ext == "ndcs" || ConainerElement.Attributes.Ext == "ss") ? true : false;
            }

            if (this.ndvs.syncJobSetting.netDocuments.selectAll) {
              ConainerElement.isChecked = (!ConainerElement.isRestricted) ? true : false;
            } else {
              if (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0) {
                let curContent = this.ndvs.syncJobSetting.netDocuments.sources.folders.find((contentElement => contentElement == ConainerElement.DocId));
                if (curContent != undefined && curContent != null && curContent != '') {
                  ConainerElement.isChecked = (!ConainerElement.isRestricted) ? true : false;
                }
              }
              if (this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0) {
                let curContent = this.ndvs.syncJobSetting.netDocuments.sources.files.find((contentElement => contentElement == ConainerElement.DocId));
                if (curContent != undefined && curContent != null && curContent != '') {
                  ConainerElement.isChecked = (!ConainerElement.isRestricted) ? true : false;
                }
              }
            }
            this.NDSContainerContents.push(ConainerElement);
            this.workSpaceContents.push(ConainerElement);
          })
          this.isNDLoading = false;
          this.updateNDSelectAllStatus(this.ndvs.syncJobSetting.netDocuments.selectAll);
        } else {
          this.isNDLoading = false;
        }

        if (this.ndNextRecord == null && !this.isFloderScrollEnded) {
          this.isFloderScrollEnded = true;
          this.ndContentType = "files"
          if (this.ndvs.syncJobSetting.netDocuments.folderType != 'ss') {
            //this.getNDContainerSearchContents(this.ndNextRecord);
            this.isFileScrollEnded = true;
            this.isNDLoading = false;
          } else {
            this.isFileScrollEnded = true;
            this.isNDLoading = false;
          }
        } else if (this.ndNextRecord == null && this.isFloderScrollEnded && !this.isFileScrollEnded) {
          this.isFileScrollEnded = true;
          this.isNDLoading = false;
        }
      } else {
        this.isFileScrollEnded = true;
        this.isNDLoading = false;
        ///////// NDUserAccess ////////////
        if (resData.error != undefined && resData.error != null && resData.error.error != undefined && resData.error.error != null && resData.error.error.error != undefined && resData.error.error.error != null) {
          if (resData.error.error.error.indexOf("you do not have access") >= 0) this.isNDSUserAccess = false;
        }
      }
    })
  }

  private getNDSWorkspaceSearchContents(cabinetId, workSpace, skipToken = null) {
    if (skipToken != null) {
      if (this.NDSExecutedPagingTokens.indexOf(skipToken) < 0) {
        this.NDSExecutedPagingTokens.push(skipToken);
      } else {
        return;
      }
    } else {
      this.NDSExecutedPagingTokens = [];
    }
    this.isNDLoading = true;
    this.isFolerLoading = true;
    this.workSpaces = [];
    this.cabinetFolders = [];
    if (skipToken == null && !this.isFloderScrollEnded) {
      this.NDSContainerContents = [];
      this.workSpaceContents = [];
      //this.NDSResponsiveHeight();
    }
    if (this.NDSContentsServiceResuest != null) this.NDSContentsServiceResuest.unsubscribe();
    this.NDSContentsServiceResuest = this.ndvs.getNetDocumentsWorkspaceContents(cabinetId, workSpace, this.ndContentType, this.NDSSearchString, skipToken, this.ndvs.syncJobSetting.syncType).subscribe((resData) => {
      if (resData.status == "success" && resData.data.Results != undefined) {
        if (resData.data.Results.length > 0) {
          resData.data.Results.forEach(WSElement => {
            ///// CONTENT RESTRICTION /////
            //if (this.componentType == "destination") {
            WSElement.isRestricted = (WSElement.Attributes.Ext == "ndflt" || WSElement.Attributes.Ext == "ndcs" || WSElement.Attributes.Ext == "ss") ? true : false;
            //}

            ///// CONTENT SELECTION /////
            if (this.ndvs.syncJobSetting.netDocuments.selectAll) {
              if (this.syncType == "netDocumentsUni" || this.syncType == "netDocumentsUniOd" || this.syncType == "netDocumentsUniTeam" || this.syncType == 'netDocMove' || this.syncType == 'netDocCopy') {
              }
            } else {
              if (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0) {
                let curContent = this.ndvs.syncJobSetting.netDocuments.sources.folders.find((contentElement => (contentElement == WSElement.DocId || contentElement == WSElement.EnvId)));
                if (curContent != undefined && curContent != null && curContent != '') {
                  WSElement.isChecked = (!WSElement.isRestricted) ? true : false;
                }
              }
              if (this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0) {
                let curContent = this.ndvs.syncJobSetting.netDocuments.sources.files.find((contentElement => contentElement == WSElement.DocId));
                if (curContent != undefined && curContent != null && curContent != '') {
                  WSElement.isChecked = (!WSElement.isRestricted) ? true : false;
                }
              }
            }
            this.NDSContainerContents.push(WSElement);
            this.workSpaceContents.push(WSElement);
            //this.NDSResponsiveHeight();
          })
          this.isNDLoading = this.isFolerLoading = false;
          if (resData.skipToken != undefined && resData.skipToken != null && resData.skipToken != "") {
            this.ndNextRecord = resData.skipToken;
          } else {
            this.isFloderScrollEnded = this.isFileScrollEnded = true;
            if (this.ndContentType == "folders") {
              this.ndContentType = "files"
              this.ndNextRecord = null;
              this.isFileScrollEnded = false;
              this.getNDSWorkspaceSearchContents(cabinetId, this.selectedWorkSpace, this.ndNextRecord)
            }
          }
        } else {
          this.isNDLoading = this.isFolerLoading = false;
          this.isFloderScrollEnded = this.isFileScrollEnded = true;
        }
      } else {
        this.isNDLoading = this.isFolerLoading = false;
        this.isFloderScrollEnded = this.isFileScrollEnded = true;
      }
    })
  }

  public getWorkspace(cabinetId, netDocData = null, skipToken = null) {
    if (cabinetId == undefined) return;
    this.isNDLoading = true;
    if (skipToken == null) {
      this.selectedWorkSpace = {};
      this.workSpaces = [];
      this.cabinetFolders = [];
      this.ndvs.syncJobSetting.netDocuments.workspaceId = "";
    }
    this.isWorkspaceLoader = true;
    this.ndvs.getWorkspace(cabinetId, skipToken, this.NDSSearchString).subscribe((resData) => {
      this.isWorkspaceLoader = false;
      this.breadcurmbClicked = false;
      if (resData.status == "success" && resData.data != undefined) {
        this.ndNextRecord = resData.skipToken;
        if (skipToken == null) {
          this.workSpaces = resData.data;
        } else {
          this.workSpaces = this.workSpaces.concat(resData.data);
        }
        if (this.workSpaces.length > 0) {
          if (netDocData != null) {
            if (netDocData.workspaceId != undefined && netDocData.workspaceId != null && netDocData.workspaceId != "") {
              let curSelWS = this.workSpaces.find((element => element.id == netDocData.workspaceId));
              this.onSelectWorkSpace(curSelWS, null, netDocData);
            } else {
              // this.workSpaces=[];
              this.showLoader = false;
              if (this.ndNextRecord == null) {
                this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId, netDocData);
              } else {
                this.isNDLoading = false;
              }
            }
          } else {
            this.showLoader = false;
            if (this.ndNextRecord == null) {
              this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId, netDocData);
            } else {
              this.isNDLoading = false;
            }
          }
        } else {
          this.showLoader = false;
          if (this.ndNextRecord == null) {
            this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId, netDocData);
          } else {
            this.isNDLoading = false;
          }
        }
      } else {
        this.showLoader = false;
        this.isNDLoading = false;
      }
    })

  }

  public getCabinets(netDocData = null) {
    this.isCabinetLoader = true;
    this.ndvs.getCabinets().subscribe((resData) => {
      this.isCabinetLoader = false;
      if (resData.status == "success" && resData.data != undefined) {
        this.cabinets = resData.data;
        if (netDocData != null) {
          let curSelCabinet = this.cabinets.find((element => element.id == netDocData.cabinetId));
          this.onSelectCabinet(curSelCabinet, null, null, netDocData);
        } else {
          this.showLoader = false;
        }

      } else {
        this.cabinets = [];
        this.showLoader = false;
      }
    })
  }

  public onSelectCabinet(cabinet, dropdown = null, modalTemplate = null, netDocData = null) {
    if (dropdown != null) dropdown.close();
    this.isNDSSearchResults = false;
    if (this.selectedCabinet.id == undefined || this.ndvs.syncJobSetting.columnOptions.length == 0) {
      this.assignSelectdCabined(cabinet, netDocData, dropdown);
    } else {
      this.modalService.open(modalTemplate).result.then((resData) => {
        if (resData == "yes") {
          this.ndvs.syncJobSetting.columnOptions = [];
          this.assignSelectdCabined(cabinet, netDocData, dropdown);
        }
      }).catch((resError) => {
        this.logService.updateLogMessage("SharePoint column property confirmation popup error => " + JSON.stringify(resError))
      });

    }
  }

  public onResetRoot() {
    if (this.breadcurmbClicked || this.isNDLoading) return;
    this.breadcurmbClicked = true;
    this.cabinetSearch = "";
    this.selectedWorkSpace = {};
    this.selectCabinetFolder = {};
    this.NDSSearchString = "";
    this.ndvs.syncJobSetting.netDocuments.breadCurmb = [];
    this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
    this.ndvs.syncJobSetting.netDocuments.selectAll = false;
    this.ndvs.syncJobSetting.netDocuments.folderId = "";
    this.ndvs.syncJobSetting.netDocuments.folderName = "";
    this.ndvs.syncJobSetting.netDocuments.cabinetId = "";
    this.ndvs.syncJobSetting.netDocuments.cabinetName = "";
    this.ndvs.syncJobSetting.netDocuments.workspaceId = "";
    this.ndvs.syncJobSetting.netDocuments.workspaceName = "";
    //this.ndvs.syncJobSetting.syncType = "";
    this.onResetScrollVars();
    this.onSelectCabinet(this.selectedCabinet);
    this.onSaveEnable();
    this.onNDSCreateFolderHide();
  }


  public onResetWorkspace() {

    if (this.breadcurmbClicked || this.isNDLoading) return;
    this.breadcurmbClicked = true;
    this.cabinetSearch = "";
    this.ndvs.syncJobSetting.netDocuments.breadCurmb = [];
    this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
    this.ndvs.syncJobSetting.netDocuments.selectAll = false;
    this.ndvs.syncJobSetting.netDocuments.folderId = "";
    this.ndvs.syncJobSetting.netDocuments.folderName = "";
    this.onResetScrollVars();
    this.onSelectWorkSpace(this.selectedWorkSpace);
    this.onSaveEnable()
    this.onNDSCreateFolderHide();
  }

  public onLoadBreadCurmp(currentFolder, breadcurmbIndex) {
    if (this.breadcurmbClicked || this.isNDLoading) return;
    this.breadcurmbClicked = true;
    this.cabinetSearch = "";
    if (breadcurmbIndex < (this.ndvs.syncJobSetting.netDocuments.breadCurmb.length - 1)) {
      let removeItems = this.ndvs.syncJobSetting.netDocuments.breadCurmb.length - breadcurmbIndex;
      this.ndvs.syncJobSetting.netDocuments.breadCurmb.splice(breadcurmbIndex + 1, removeItems);
    }
    this.ndvs.syncJobSetting.netDocuments.folderId = currentFolder.DocId;
    this.ndvs.syncJobSetting.netDocuments.folderName = currentFolder.Attributes.Name;
    this.ndvs.syncJobSetting.netDocuments.sources = { folders: [], files: [] };
    this.ndvs.syncJobSetting.netDocuments.selectAll = false;
    this.onResetScrollVars();

    this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, currentFolder.DocId);
  }

  public getExt(sourceType) {
    let types = { folder: "ndfld", workspace: "ndws" }
    return types[sourceType];
  }


  public assignSelectdCabined(cabinet, netDocData, dropdown) {
    this.selectedCabinet = cabinet;
    this.selectedWorkSpace = {}
    this.selectCabinetFolder = {};
    this.workSpaces = [];
    this.cabinetFolders = [];
    this.workSpaceContents = [];
    this.NDSContainerContents = [];
    this.cabinetSearch = "";
    this.onResetScrollVars();
    if (netDocData == null) this.ndvs.syncJobSetting.netDocuments.breadCurmb = [];
    this.ndvs.syncJobSetting.netDocuments.cabinetId = this.selectedCabinet.id;
    this.ndvs.syncJobSetting.netDocuments.cabinetName = this.selectedCabinet.name
    this.ndvs.syncJobSetting.netDocuments.repositoryId = this.selectedCabinet.repositoryId;
    if (netDocData != null) {
      if (netDocData.folderId != undefined && netDocData.folderId != null && netDocData.folderId != "") {
        if (netDocData.workspaceId != undefined && netDocData.workspaceId != null && netDocData.workspaceId != "") {
          this.onSelectWorkSpace({ id: netDocData.workspaceId, name: netDocData.workspaceName, envId: netDocData.workspaceEnvId }, null, netDocData);
        } else {

          this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, netDocData.folderId);
          // this.onSelectWorkSpace({id:netDocData.workspaceId,name:netDocData.workspaceName},null,netDocData);
        }
      } else {
        if (netDocData.workspaceId != undefined && netDocData.workspaceId != null && netDocData.workspaceId != "") {
          this.onSelectWorkSpace({ id: netDocData.workspaceId, name: netDocData.workspaceName, envId: netDocData.workspaceEnvId }, null, netDocData);
        } else {
          if (this.ndvs.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspace(this.ndvs.syncJobSetting.netDocuments.cabinetId, netDocData)
          // this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId,netDocData); 
        }
      }
    } else {
      if (this.ndvs.syncJobSetting.netDocuments.cabinetId != undefined) this.getWorkspace(this.ndvs.syncJobSetting.netDocuments.cabinetId)
      // this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId);
    }
  }

  public getWorkspaceContents(cabinetId, workspaceId, skipToken = null) {
    if (this.NDSSearchString) {
      this.onSearchNDSContents(this.ndNextRecord || this.ndFilesNextRecord)
      return;
    }
    this.isNDLoading = true;
    this.isFolerLoading = true;
    if (skipToken != null) {
      if (this.executedPagingTokens.indexOf(skipToken) < 0) {
        this.executedPagingTokens.push(skipToken);
      } else {
        //return;
      }
    } else {
      this.executedPagingTokens = [];
    }
    this.workSpaces = [];
    this.cabinetFolders = [];
    if (skipToken == null && !this.isFloderScrollEnded) {
      this.workSpaceContents = [];
      this.NDSContainerContents = [];
    }
    if (this.ndContentType == "folders" && this.ndvs.syncJobSetting.netDocuments.folderId == "") {
      if (this.serviceResuest != null) this.serviceResuest.unsubscribe();
      this.serviceResuest = this.ndvs.getNetDocumentsFolders(cabinetId, workspaceId, skipToken).subscribe((resData) => {
        this.showLoader = false;
        this.breadcurmbClicked = false;
        if (resData.status == "success" && resData.data != undefined) {
          if (resData.data.length > 0) {
            resData.data.forEach(element => {

              ///// CONTENT RESTRICTION /////
              if (this.syncType != 'netDocumentsUniTeam') {
                element.isRestricted = (element.Attributes.Ext == "ndflt" || element.Attributes.Ext == "ndcs" || element.Attributes.Ext == "ss") ? true : false;
              }

              ///// CONTENT SELECTION /////
              if (this.ndvs.syncJobSetting.netDocuments.selectAll) {
                element.isChecked = element.isRestricted ? false : true;
              } else {
                if (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0) {
                  let curContent = this.ndvs.syncJobSetting.netDocuments.sources.folders.find((contentElement => contentElement == element.DocId));
                  if (curContent != undefined && curContent != null && curContent != '') {
                    element.isChecked = true;
                  }
                }
                if (this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0) {
                  let curContent = this.ndvs.syncJobSetting.netDocuments.sources.files.find((contentElement => contentElement == element.DocId));
                  if (curContent != undefined && curContent != null && curContent != '') {
                    element.isChecked = true;
                  }
                }
              }
              this.workSpaceContents.push(element);
            });

            this.NDSContainerContents = [];
            this.workSpaceContents.forEach(element => {
              this.NDSContainerContents.push(element)
            });
            this.updateNDSelectAllStatus(this.ndvs.syncJobSetting.netDocuments.selectAll);
            if (resData.skipToken != undefined && resData.skipToken != null && resData.skipToken != "") {
              this.ndFilesNextRecord = resData.skipToken;
              this.isFolerLoading = false;
              this.isNDLoading = false;
            } else {
              this.isNDLoading = false;
              this.isFolerLoading = false;
              this.isFloderScrollEnded = true;
              this.ndContentType = "files";
              this.getWorkspaceContents(cabinetId, workspaceId)
            }
          } else {
            this.isNDLoading = false;
            this.isFolerLoading = false;
            this.isFloderScrollEnded = true;
            this.ndContentType = "files";
            this.getWorkspaceContents(cabinetId, workspaceId)
          }
        } else {
          this.isNDLoading = false;
          this.isFolerLoading = false;
          this.isFloderScrollEnded = true;
          this.ndContentType = "files";
          this.getWorkspaceContents(cabinetId, workspaceId)
        }
      });
    } else {
      if (this.serviceResuest != null) this.serviceResuest.unsubscribe();
      if (this.ndvs.syncJobSetting.netDocuments.folderId == "") {
        this.serviceResuest = this.ndvs.getNetDocumentsWorkspaceContents(cabinetId, this.selectedWorkSpace, this.ndContentType, this.cabinetSearch = "", skipToken).subscribe((resData) => {
          this.isFolerLoading = false;
          this.showLoader = false;
          this.breadcurmbClicked = false;
          if (resData.status == "success" && resData.data != undefined) {
            if (resData.data.Results != undefined && resData.data.Results.length > 0) {
              this.ndFilesNextRecord = resData.skipToken;
              this.workSpaceContents = this.workSpaceContents.concat(resData.data.Results);
              this.onRestrictFile();
              this.workSpaceContents.forEach(element => {

                ///// CONTENT RESTRICTION /////
                if (this.syncType != 'netDocumentsUniTeam') {
                  element.isRestricted = (element.Attributes.Ext == "ndflt" || element.Attributes.Ext == "ndcs" || element.Attributes.Ext == "ss") ? true : false;
                }

                ///// CONTENT SELECTION /////
                if (this.ndvs.syncJobSetting.netDocuments.selectAll) {
                  element.isChecked = element.isRestricted ? false : true;
                } else {
                  if (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0) {
                    let curContent = this.ndvs.syncJobSetting.netDocuments.sources.folders.find((contentElement => contentElement == element.DocId));
                    if (curContent != undefined && curContent != null && curContent != '') {
                      element.isChecked = true;
                    }
                  }
                  if (this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0) {
                    let curContent = this.ndvs.syncJobSetting.netDocuments.sources.files.find((contentElement => contentElement == element.DocId));
                    if (curContent != undefined && curContent != null && curContent != '') {
                      element.isChecked = true;
                    }
                  }
                }
              });
              this.NDSContainerContents = [];
              this.workSpaceContents.forEach(element => {
                this.NDSContainerContents.push(element)
              });
              this.isNDLoading = false;
              this.updateNDSelectAllStatus(this.ndvs.syncJobSetting.netDocuments.selectAll);
            } else if (resData.data.Results != undefined && resData.data.Results.length == 0) {
              this.isNDLoading = false;
            }
            if (this.ndFilesNextRecord == null && !this.isFileScrollEnded) {
              this.isFloderScrollEnded = true;
              this.isFileScrollEnded = true;
              this.ndContentType = "files"
              this.isNDLoading = false;
              //this.getWorkspaceContents(cabinetId, workspaceId, this.ndFilesNextRecord);
            } else if (this.ndFilesNextRecord == null && this.isFloderScrollEnded && !this.isFileScrollEnded) {
              this.isFileScrollEnded = true;
              this.isNDLoading = false;
            }

            this.onSaveEnable();
          }
        })
      } else {
        this.serviceResuest = this.ndvs.getFilesAdnFolders(cabinetId, workspaceId, this.cabinetSearch, this.ndContentType, skipToken).subscribe((resData) => {
          this.isFolerLoading = false;
          this.showLoader = false;
          this.breadcurmbClicked = false;
          if (resData.status == "success" && resData.data != undefined) {
            if (resData.data.Results != undefined && resData.data.Results.length > 0) {
              this.ndFilesNextRecord = resData.skipToken;
              this.workSpaceContents = this.workSpaceContents.concat(resData.data.Results);
              this.onRestrictFile();
              this.workSpaceContents.forEach(element => {

                ///// CONTENT RESTRICTION /////
                if (this.syncType != 'netDocumentsUniTeam') {
                  element.isRestricted = (element.Attributes.Ext == "ndflt" || element.Attributes.Ext == "ndcs" || element.Attributes.Ext == "ss") ? true : false;
                }

                ///// CONTENT SELECTION /////
                if (this.ndvs.syncJobSetting.netDocuments.selectAll) {
                  element.isChecked = element.isRestricted ? false : true;
                } else {
                  if (this.ndvs.syncJobSetting.netDocuments.sources.folders.length > 0) {
                    let curContent = this.ndvs.syncJobSetting.netDocuments.sources.folders.find((contentElement => contentElement == element.DocId));
                    if (curContent != undefined && curContent != null && curContent != '') {
                      element.isChecked = true;
                    }
                  }
                  if (this.ndvs.syncJobSetting.netDocuments.sources.files.length > 0) {
                    let curContent = this.ndvs.syncJobSetting.netDocuments.sources.files.find((contentElement => contentElement == element.DocId));
                    if (curContent != undefined && curContent != null && curContent != '') {
                      element.isChecked = true;
                    }
                  }
                }
              });
              this.NDSContainerContents = [];
              this.workSpaceContents.forEach(element => {
                this.NDSContainerContents.push(element)
              });
              this.isNDLoading = false;
              this.updateNDSelectAllStatus(this.ndvs.syncJobSetting.netDocuments.selectAll);
            } else if (resData.data.Results != undefined && resData.data.Results.length == 0) {
              this.isNDLoading = false;
            }
            if (this.ndFilesNextRecord == null && !this.isFileScrollEnded) {
              this.isFloderScrollEnded = true;
              this.isFileScrollEnded = true;
              this.ndContentType = "files"
              this.isNDLoading = false;
              //this.getWorkspaceContents(cabinetId, workspaceId, this.ndFilesNextRecord);
            } else if (this.ndFilesNextRecord == null && this.isFloderScrollEnded && !this.isFileScrollEnded) {
              this.isFileScrollEnded = true;
              this.isNDLoading = false;
            }

            this.onSaveEnable();
          }
        })
      }
    }
  }
  public onChangeCabinetSearch() {
    this.ndNextRecord = null;
    if (this.cabinetSearch != "") return;
    if (this.NDSSearchString == "") return;
    this.isNDSSearchResults = false;
    this.onResetScrollVars();
    if (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") {
      let contentSource: string = "";
      contentSource = (this.ndvs.syncJobSetting.netDocuments.folderId != "") ? this.ndvs.syncJobSetting.netDocuments.folderId : this.ndvs.syncJobSetting.netDocuments.workspaceId;

      this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
    } else {
      this.isWorkspaceLoader = true;
      this.workSpaces = [];
      this.cabinetFolders = [];
      if (this.selectedCabinet.id != undefined) {
        this.ndvs.getWorkspace(this.selectedCabinet.id, this.ndNextRecord).subscribe((resData) => {
          this.isWorkspaceLoader = false;
          if (resData.status == "success" && resData.data != undefined) {
            this.workSpaces = resData.data;
          }
          this.getCabinetFolders(this.ndvs.syncJobSetting.netDocuments.cabinetId);
        });
      }
    }
  }



  public onNDScroll(event) {
    if ((event.target.scrollHeight - event.target.clientHeight) <= (event.target.scrollTop + 100) && !((event.target.scrollHeight - event.target.clientHeight) == 0 && event.target.scrollTop == 0)) {
      if (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") {
        if (this.isFileScrollEnded) return;
        let contentSource: string = "";
        if ((this.ndvs.syncJobSetting.netDocuments.workspaceId != "" && this.ndvs.syncJobSetting.netDocuments.folderId != "") || (this.ndvs.syncJobSetting.netDocuments.workspaceId == "" && this.ndvs.syncJobSetting.netDocuments.folderId != "")) {
          contentSource = this.ndvs.syncJobSetting.netDocuments.folderId;
        } else {
          contentSource = this.ndvs.syncJobSetting.netDocuments.workspaceId;
        }
        //this.isNDLoading = true;
        this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
      } else {
        if (this.ndNextRecord != null) this.getWorkspace(this.ndvs.syncJobSetting.netDocuments.cabinetId, null, this.ndNextRecord);
      }
    }
  }

  /*   public onSearchKeyPress(event) {
      if (event.keyCode == 13) this.onSearchWorkspace();
    }
   */
  public onSearchWorkspace(skipToken = null) {
    if (this.cabinetSearch.trim() == '' || this.cabinetSearch.length < 3 || this.isNDLoading) return;
    if (skipToken == null) this.onResetScrollVars();
    if (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") {
      let contentSource: string = "";
      contentSource = (this.ndvs.syncJobSetting.netDocuments.folderId != "") ? this.ndvs.syncJobSetting.netDocuments.folderId : this.ndvs.syncJobSetting.netDocuments.workspaceId;

      this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, contentSource, this.ndFilesNextRecord)
    } else {
      this.isWorkspaceLoader = true;
      this.ndvs.getSearchWorkspace(this.selectedCabinet.id, this.cabinetSearch, skipToken).subscribe((resData) => {
        this.isWorkspaceLoader = false;
        if (resData.status == "success" && resData.data != undefined) {
          this.ndNextRecord = resData.skipToken;
          if (skipToken == null) {
            this.workSpaces = resData.data;
          } else {
            this.workSpaces = this.workSpaces.concat(resData.data);
          }
        } else {
          this.showLoader = false;
        }
        // if(this.ndNextRecord!=null) this.onSearchWorkspace(this.ndNextRecord);      
      });
    }
  }

  public onSearchKeyPress(event) {
    if (this.isNDLoading) return;
    if (event.keyCode == 13 && !this.isNDSSearchButtonDisabled) this.onSearchNDSContents();
  }

  public onSearchNDSContents(skipToken = null) {
    if (this.isNDLoading) return;
    if (this.cabinetSearch.trim() == '' || this.cabinetSearch.length < 3) return;
    if (skipToken == null) this.onResetScrollVars();
    this.isNDSSearchButtonDisabled = false;
    this.NDSSearchString = this.cabinetSearch.trim();
    if (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") {
      this.isNDSSearchResults = true;
      if (this.ndvs.syncJobSetting.netDocuments.folderId != "") this.getNDSFolderSearchContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, this.ndvs.syncJobSetting.netDocuments.folderId, skipToken);
      if (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" && this.ndvs.syncJobSetting.netDocuments.folderId == "") this.getNDSWorkspaceSearchContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, this.selectedWorkSpace, skipToken);
    } else {
      //this.getNDSWorkspaceSearch(this.ndvs.syncJobSetting.netDocuments.cabinetId);
      this.getWorkspace(this.ndvs.syncJobSetting.netDocuments.cabinetId)
    }
  }

  public getCabinetFolders(cabinetId, netDocData = null) {
    this.isNDLoading = true;
    this.selectCabinetFolder = {};
    this.ndvs.syncJobSetting.netDocuments.folderId = "";
    this.ndvs.syncJobSetting.netDocuments.folderName = "";
    let recordFrom = null;
    this.isCabinetFolderLoader = true;
    this.ndvs.getCabinetFolder(cabinetId, recordFrom).subscribe((resData) => {
      this.isCabinetFolderLoader = false;
      if (resData.status == "success" && resData.data != undefined) {
        this.cabinetFolders = resData.data;
        if (this.cabinetFolders.length > 0) {
          if (netDocData != null) {
            let curSelCabFolder = this.cabinetFolders.find((element => element.DocId == netDocData.folderId));
            if (curSelCabFolder != undefined && curSelCabFolder != null) {
              this.selectCabinetFolder = curSelCabFolder
              this.ndvs.syncJobSetting.netDocuments.folderId = this.selectCabinetFolder.DocId;
              this.ndvs.syncJobSetting.netDocuments.folderName = this.selectCabinetFolder.Attributes.Name;
              this.getWorkspaceContents(this.ndvs.syncJobSetting.netDocuments.cabinetId, this.selectCabinetFolder.DocId);
            } else {
              if (netDocData.contents != undefined && netDocData.contents != null && netDocData.contents.length > 0) {
                this.cabinetFolders.forEach(foldFindEle => {
                  foldFindEle.isChecked = (netDocData.contents.indexOf(foldFindEle.DocId) >= 0) ? true : false;
                })
              }
              this.showLoader = false;
              this.isNDLoading = false;
            }
          } else {
            this.showLoader = false;
            this.isNDLoading = false;
            // this.onSelectWorkSpace(this.workSpaces[0]);   
          }
        }
      } else {
        this.showLoader = false;
        this.isNDLoading = false;
        this.workSpaces = [];
      }
    })
  }

  public onRestrictFile() {
    this.workSpaceContents.forEach(fileElement => {
      if (this.ndvs.syncJobSetting.sharePoint.connectionType != undefined && this.ndvs.syncJobSetting.sharePoint.connectionType != null && this.ndvs.syncJobSetting.sharePoint.connectionType == 'online') {
        if (fileElement.Attributes.Size >= 100000000 || fileElement.Attributes.Name.length >= 256 || fileElement.Attributes.Name.charAt(0) == "~" || !this.isfileCharValidated(fileElement.Attributes.Name)) {
          fileElement.isRestricted = true;
        } else {
          fileElement.isRestricted = false;
        }
      } else if (this.ndvs.syncJobSetting.sharePoint.connectionType != undefined && this.ndvs.syncJobSetting.sharePoint.connectionType != null && this.ndvs.syncJobSetting.sharePoint.connectionType == 'onpremises') {
        if (fileElement.Attributes.Size >= 15000000 || fileElement.Attributes.Name.length >= 256 || fileElement.Attributes.Name.charAt(0) == "~" || !this.isfileCharValidated(fileElement.Attributes.Name)) {
          fileElement.isRestricted = true;
        } else {
          fileElement.isRestricted = false;
        }
      } else {
        fileElement.isRestricted = false;
      }
    });
  }

  private isfileCharValidated(fileName) {
    let validateChars = ['"', '*', ':', '<', '>', '?', '/', '\\', '|'];
    let isAvailable: boolean = true;
    validateChars.forEach((char) => {
      if (fileName.indexOf(char) >= 0) isAvailable = false;
    })
    return isAvailable;
  }


  public updateNDSelectAllStatus(selectAllStstus = null) {
    //selectAllStstus = true;
    if (selectAllStstus != null) {
      this.ndvs.syncJobSetting.netDocuments.selectAll = selectAllStstus;
    } else {
      let restricetedFiles = this.workSpaceContents.filter(resFile => (resFile.isRestricted == true));
      let filesCount = (restricetedFiles == undefined || restricetedFiles == null || restricetedFiles.length == 0) ? this.workSpaceContents.length : (this.workSpaceContents.length - restricetedFiles.length)
      if ((this.ndvs.syncJobSetting.netDocuments.sources.folders.length + this.ndvs.syncJobSetting.netDocuments.sources.files.length) == filesCount) {
        this.ndvs.syncJobSetting.netDocuments.selectAll = true;
      } else {
        this.ndvs.syncJobSetting.netDocuments.selectAll = false;
      }
    }
    this.onSaveEnable();
  }


  public onCheckSaveEnable() {
    this.isDisabledSave = true;
    if (this.isNDLoading || this.isFolerLoading || this.isTeamsLoading) return;
    switch (this.ndvs.syncJobSetting.syncType) {
      case "bidirectionalTeam":
        this.isDisabledSave = (this.ndvs.syncJobSetting.syncFrequencyInSecond >= 30 && this.ndvs.syncJobSetting.netDocuments.cabinetId != "" && this.ndvs.syncJobSetting.teams.connectionId != '' && this.ndvs.syncJobSetting.teams.teamId != '') ? false : true;
        break;
      case "teamUni":
        this.isDisabledSave = (this.ndvs.syncJobSetting.syncFrequencyInSecond >= 30 && this.ndvs.syncJobSetting.netDocuments.cabinetId != "" && (this.ndvs.syncJobSetting.netDocuments.workspaceId != "" || this.ndvs.syncJobSetting.netDocuments.folderId != "") && this.ndvs.syncJobSetting.teams.connectionId != '' && (this.ndvs.syncJobSetting.teams.sources.folders.length > 0 || this.ndvs.syncJobSetting.teams.sources.files.length > 0 || this.ndvs.syncJobSetting.teams.sources.channels.length > 0)) ? false : true;
        break;
    }
  }

  public onSaveSyncJob(teamId, resFolderData, channel = null) {
    this.ndvs.syncJobSetting.netDocuments.siteUrl = this.ndvs.currentUserLicense.NDApiUrl;
    this.ndvs.syncJobSetting.netDocuments.userName = this.ndvs.currentUser.displayName;
    this.ndvs.syncJobSetting.createdBy = this.ndvs.currentUser.email;
    this.ndvs.syncJobSetting.netDocuments.isPathBroken = false;
    this.ndvs.syncJobSetting.sharePoint.isConnectionBroken = false;
    this.ndvs.syncJobSetting.sharePoint.isPathBroken = false;
    this.ndvs.syncJobSetting.oneDrive.isPathBroken = false;
    this.ndvs.syncJobSetting.selectAll = false;
    this.ndvs.syncJobSetting.teams = {
      connectionId: this.global.connectionData._id,
      connectionType: "online",
      teamId: this.global.selectedGroup.id || this.global.selectedGroup.Id,
      teamName: this.global.selectedGroup.displayName || this.global.selectedGroup.DisplayName,
      channelId: channel ? channel.id : "",
      channelName: channel ? channel.name : "",
      channeWebUrl: "",
      folderId: "",
      folderName: "",
      folderWebUrl: "",
      selectAll: true,
      breadCrumb: [],
      sources: {
        folders: [],
        files: [],
        channels: []
      },
      channelWebUrl: ""
    }
    switch (this.ndvs.syncJobSetting.syncType) {
      case "bidirectionalTeam":
        this.ndvs.syncJobSetting.selectAll = this.ndvs.syncJobSetting.netDocuments.selectAll;
        this.ndvs.syncJobSetting.teams.selectAll = true;
        break;
      case "netDocumentsUniTeam":
        this.ndvs.syncJobSetting.selectAll = this.ndvs.syncJobSetting.netDocuments.selectAll;
        this.ndvs.syncJobSetting.teams.selectAll = true;
        break;
      case "teamUni":
        if (this.ndvs.syncJobSetting.teams.selectAll) {
          this.ndvs.syncJobSetting.selectAll = true;
          this.ndvs.syncJobSetting.netDocuments.selectAll = false;
          this.ndvs.syncJobSetting.teams.selectAll = true;
        }
        break;
    }

    var breadCrumb = [...this.ndvs.syncJobSetting.netDocuments.breadCurmb]
    if (resFolderData) {
      this.ndvs.syncJobSetting.netDocuments.folderId = resFolderData.standardAttributes.id
      this.ndvs.syncJobSetting.netDocuments.folderName = resFolderData.standardAttributes.name
      if (this.ndvs.syncJobSetting.netDocuments.breadCurmb[this.ndvs.syncJobSetting.netDocuments.breadCurmb.length - 1]?.DocId !== resFolderData.standardAttributes.id) {
        breadCrumb.push({
          DocId: resFolderData.standardAttributes.id,
          EnvId: resFolderData.standardAttributes.envId,
          Attributes: {
            Name: resFolderData.standardAttributes.name,
            Ext: resFolderData.standardAttributes.extension
          }
        })
      }
    }

    this.ndvs.syncJobSetting.netDocuments.breadCurmb = breadCrumb;
    return this.ndvs.syncJobSetting;
  }


  private onResetScrollVars() {
    this.ndNextRecord = null;
    this.ndFilesNextRecord = null;
    this.ndContentType = "folders";
    this.isFloderScrollEnded = false;
    this.isFileScrollEnded = false;
    this.NDSSearchString = "";
    this.onNDSCreateFolderHide();
  }


  public onCloseModal() {
    this.modalService.dismissAll();
  }

  private setFormData() {
    if (this.global.isChannelEdit) {
      this.formChannel = this.formBuilder.group({
        channelName: [this.global.selectedChannel.DisplayName, Validators.required],
        channelDescription: [this.global.selectedChannel.Description],
        channelId: [this.global.selectedChannel.Id, Validators.required],
      })
    } else if (this.requestFromBulkEdit) {
      this.formChannel = this.formBuilder.group({
        channelName: [this.channelDetail.name, Validators.required],
        channelDescription: [this.channelDetail.description],
        optionPrivacy: [this.channelDetail.membershipType]
      })
    } else {
      this.formChannel = this.formBuilder.group({
        channelName: ["", Validators.required],
        channelDescription: [""],
        optionPrivacy: ["standard"],
        optionSync: [""],
        selectedCabinetName: [this.selectedCabinet.name],
        cabinetSearch: [this.cabinetSearch],
        newFolderName: [this.folderCreationError],
        NDSFolderName: [this.NDSFolderName]
      })
    }

    this.formChannel.controls["channelName"].valueChanges.subscribe(() => {
      if (this.isRequestError || this.isRequestFailed || this.isChannelExist) this.onChangeChannelName();
    })
  }

  public checkSync(data) {
    if (!data.syncData)
      return true;


    var channelFound = false;
    var teamsSyncData = data.teamsSyncData;
    //var isSyncScheduled = data.isSyncScheduled;
    if (teamsSyncData) {
      var channels = JSON.parse(teamsSyncData).filter(item => item.teams.channelId != "");
      if (channels.length > 0) {
        channelFound = true;
      } else {
        channelFound = false;
      }
    }
    return channelFound;
  }

  public onChangeChannelName() {
    this.isRequestError = false;
    this.isRequestFailed = false;
    this.isChannelExist = false;
  }
  public onChangeChannelMembers({ members, removedMembers }) {
    this.newPrivateChannelMembers = members;
    this.removedMembers = removedMembers;
    console.log("PT::onChangeChannelMembers::", this.newPrivateChannelMembers, this.removedMembers)
  }

  public onCreateChannel() {

    if (this.checkDisable()) return;
    this.channelCreateLoader = true;
    this.isRequestError = false;
    this.isRequestFailed = false;
    this.isChannelExist = false;
    this.message = "";

    let sendData = this.formChannel.value;

    sendData.channelName = this.formChannel.controls["channelName"].value; //This is done since on folder exisit and trying for the second time this data is removed.
    sendData.channelDescription = this.formChannel.controls["channelDescription"].value; //This is done since on folder exisit and trying for the second time this data is removed.

    if (!this.global.isChannelEdit) {
      sendData.newFolderName = this.formChannel.controls["newFolderName"].value;
    }
    sendData.connectionId = this.global.connectionData._id;
    sendData.teamId = this.global.selectedGroup.Id;
    sendData.teamName = this.global.selectedGroup.DisplayName;

    this.formChannel.controls["channelName"].disable();
    this.formChannel.controls["channelDescription"].disable();

    if (!this.global.isChannelEdit) {

      if (this.syncCheck) { // Creation of Channel with Sync
        if (!this.global.haveSyncAddOn) {
          this.channelCreateLoader = false;
          this.toaster.error("Sync Addon is not active. This team require Sync Addon.", "Failure", { timeOut: 5000 });
          return;
        }
        let ignoreFolderCreation = false;
        if (this.syncType === "netDocumentsUniTeam" || this.syncType === "bidirectionalTeam") ignoreFolderCreation = true;
        this.ndvs.createFolder(sendData.newFolderName || sendData.channelName, this.ndvs.syncJobSetting.netDocuments.folderId, this.ndvs.syncJobSetting.netDocuments.cabinetId, ignoreFolderCreation).subscribe((resFolderDataReceived) => {
          if (resFolderDataReceived.status == "success") {
            let resFolderData: any = {};
            if (ignoreFolderCreation) {
              if (this.ndvs.syncJobSetting.netDocuments.breadCurmb.length > 0) {
                const breadCurmbLastData = this.ndvs.syncJobSetting.netDocuments.breadCurmb[this.ndvs.syncJobSetting.netDocuments.breadCurmb.length - 1];
                resFolderData = {
                  data: {
                    standardAttributes: {
                      id: breadCurmbLastData.DocId,
                      envId: breadCurmbLastData.EnvId,
                      name: breadCurmbLastData.Attributes.Name,
                      extension: breadCurmbLastData.Attributes.Ext
                    }
                  }
                }
              }
            } else {
              resFolderData = { ...resFolderDataReceived };
            }
            this.teamsService.createChannel({ ...sendData, channelName: sendData.newFolderName || sendData.channelName }).subscribe((res) => {
              if (res.data.Id == null) {
                this.toaster.error("Channel not created. " + res.data.message, "Failure", { timeOut: 5000 });
                this.channelCreateLoader = false;
                return;
              }
              var resChannel = res.data;
              if (res.status == 'success' && this.newPrivateChannelMembers.length > 0) {
                this.teamsService.addPrivateChannelMembers({ teamId: sendData.teamId, channelId: resChannel.Id, members: this.newPrivateChannelMembers }).subscribe(respo => {
                })
                //this.newPrivateChannelMembers
              }

              if (res.status == 'success') {
                let syncJson = this.onSaveSyncJob(this.global.selectedGroup.Id, resFolderData.data, { id: resChannel.Id, name: resChannel.DisplayName });
                this.ndvs.saveSyncJob(syncJson).subscribe((resData) => {
                  if (resData.status == "success") {

                    //currentTeam.syncId = data.syncId;
                    var teamsSyncData = JSON.stringify([{
                      _id: resData.data.syncData,
                      teams: {
                        teamName: sendData.teamName,
                        teamId: sendData.teamId,
                        channelName: sendData.newFolderName || sendData.channelName,
                        channelId: resChannel.Id
                      }
                    }])

                    let data = { syncData: resData.data.syncData, Id: sendData.teamId, "DisplayName": sendData.teamName, id: sendData.teamId, "displayName": sendData.teamName, isSyncScheduled: true, teamsSyncData: teamsSyncData, createdDateTime: HelperService.getISOTimeNow() };
                    //console.log("emitting==", data)
                    //this.syncChannel.emit(data);

                    if (this.tabCreationCheck) {
                      this.channelCreateLoader = true;
                      setTimeout(() => {

                        this.teamsService.createNewTabInDB({ channelId: res.data.Id, teamId: sendData.teamId, config: this.tabConfigSetting.tabConfig.config, tabName: this.tabConfigSetting.tabConfig.tabName, tabDetail: null }).subscribe(resp => {
                          //console.log("PT::createNewTabInDB::IF::data::", data)
                          this.global.channelCreationSync.emit(data);
                          if (resp.status === "success" && (resp.data.error == null || resp.data.error == undefined)) {
                            this.channelCreateLoader = false;
                            this.toaster.success("Channel successfully created", "Success", { extendedTimeOut: 10 });
                            this.modalService.dismissAll({ ...sendData, ...res.data, membershipType: sendData.optionPrivacy });
                          } else {
                            this.toaster.success("Channel successfully created", "Success", { extendedTimeOut: 10 });
                            this.toaster.error("Tab not created. " + resp.message, "Failure", { timeOut: 5000 });
                            this.channelCreateLoader = false;
                            this.isRequestError = true;
                            this.modalService.dismissAll({ ...sendData, ...res.data, membershipType: sendData.optionPrivacy });
                          }
                        }, (error) => {
                          this.toaster.error("Channel successfully created", "Failure", { extendedTimeOut: 10 });
                          this.toaster.error("Tab not created.", "Failure", { timeOut: 5000 });
                          this.channelCreateLoader = false;
                          this.isRequestError = true;
                          this.modalService.dismissAll();
                        })

                        /* this.onCreateTab(sendData.teamId, res.data.Id).then((resp) => {
                          this.global.channelCreationSync.emit(data);
                          this.channelCreateLoader = false;
                          this.toaster.success("Channel successfully created", "Success", { extendedTimeOut: 10 });
                          this.modalService.dismissAll({...sendData,...res.data, membershipType:sendData.optionPrivacy});
                        }); */
                      }, 3000)
                    } else {
                      //console.log("PT::createNewTabInDB::ELSE::data::", data)
                      this.global.channelCreationSync.emit(data);
                      this.toaster.success("Channel successfully created", "Success", { extendedTimeOut: 10 });
                      this.modalService.dismissAll({ ...sendData, ...res.data, membershipType: sendData.optionPrivacy });
                      this.channelCreateLoader = false;
                    }
                  }
                }, (error) => {
                  this.channelCreateLoader = false;
                  this.toaster.error(error.message, "Failure", { extendedTimeOut: 10 });
                });
              } else {
                this.formChannel.controls["channelName"].enable();
                this.formChannel.controls["channelDescription"].enable();
                this.isChannelExist = true;
                this.isRequestFailed = true;
                this.message = res.message.error.message;
                this.toaster.error(this.message, "Failure", { timeOut: 5000 });
              }
            }, (error) => {
              this.channelCreateLoader = false;
              this.isRequestError = true;
            })


          } else if (resFolderDataReceived.status == 'failed') {
            this.channelCreateLoader = false;
            this.toaster.error(resFolderDataReceived.message, "Folder creation in netdocuments is failed.", { extendedTimeOut: 10 });
            this.folderCreationError = sendData.newFolderName || sendData.channelName;//"The parent folder already contains a subfolder with this name."
            this.folderCreationErrorMessage = resFolderDataReceived.error;
          }
        })
      } else { // Creation of Channel without Sync
        this.teamsService.createChannel(sendData).subscribe((res) => {
          if (res.data.Id == null) {
            this.toaster.error("Channel not created. " + res.data.message, "Failure", { timeOut: 5000 });
            this.channelCreateLoader = false;
            return;
          }
          if (res.status == 'success' && this.newPrivateChannelMembers.length > 0) {
            this.teamsService.addPrivateChannelMembers({ teamId: sendData.teamId, channelId: res.data.Id, members: this.newPrivateChannelMembers }).subscribe(respo => {
            })
            //this.newPrivateChannelMembers
          }
          if (res.status == 'success') {
            if (this.tabCreationCheck) {
              this.channelCreateLoader = true;
              setTimeout(() => {

                this.teamsService.createNewTabInDB({ channelId: res.data.Id, teamId: sendData.teamId, config: this.tabConfigSetting.tabConfig.config, tabName: this.tabConfigSetting.tabConfig.tabName, tabDetail: null }).subscribe(resp => {
                  //console.log("createNewTabInDB====channel===without sync==resp====", resp)
                  if (resp.status === "success" && (resp.data.error == null || resp.data.error == undefined)) {

                    this.toaster.success("Channel successfully created", "Success", { extendedTimeOut: 10 });
                    this.modalService.dismissAll({ ...sendData, ...res.data, membershipType: sendData.optionPrivacy });
                    this.channelCreateLoader = false;
                  } else {
                    this.toaster.success("Channel successfully created", "Success", { extendedTimeOut: 10 });
                    this.toaster.error("Tab not created. " + resp.message, "Failure", { timeOut: 5000 });
                    this.channelCreateLoader = false;
                    this.isRequestError = true; this.modalService.dismissAll({ ...sendData, ...res.data, membershipType: sendData.optionPrivacy });
                  }
                }, (error) => {
                  this.toaster.success("Channel successfully created", "Success", { extendedTimeOut: 10 });
                  this.toaster.error("Tab not created.", "Failure", { timeOut: 5000 });
                  this.channelCreateLoader = false;
                  this.isRequestError = true;
                  this.modalService.dismissAll();
                })
                /* this.onCreateTab(sendData.teamId, res.data.Id).then((resp) => {

                  //console.log("resp===", resp)
                  this.toaster.success("Channel successfully created", "Success", { extendedTimeOut: 10 });
                  this.modalService.dismissAll({...sendData,...res.data, membershipType:sendData.optionPrivacy});
                }).catch(() => {
                  this.channelCreateLoader = false;
                  this.isRequestError = true;
                }) */
              }, 3000)
            } else {
              this.toaster.success("Channel successfully created", "Success", { extendedTimeOut: 10 });
              this.channelCreateLoader = false;
              this.modalService.dismissAll({ ...sendData, ...res.data, membershipType: sendData.optionPrivacy });
            }
          } else {
            this.formChannel.controls["channelName"].enable();
            this.formChannel.controls["channelDescription"].enable();
            this.channelCreateLoader = false;
            this.isChannelExist = true;
            this.isRequestFailed = true;
            this.message = res.message.error.message;
            /* if (res.message.indexOf("NameAlreadyExists") >= 0) {
              this.message = "Channel name already exist";
            } else {
            } */
          }
        }, (error) => {
          this.channelCreateLoader = false;
          this.isRequestError = true;
        })
      }


    } else {
      this.teamsService.updateChannel(sendData).subscribe((res) => {
        //console.log(this.global.selectedChannel);
        if (res.status == 'success' && this.global.selectedChannel.membershipType.toLowerCase() == "private") {
          this.teamsService.updatePrivateChannelMembers({ teamId: this.global.selectedGroup.Id, channelId: this.global.selectedChannel.Id, members: this.newPrivateChannelMembers, removedMembers: this.removedMembers }).subscribe(respo => {
          })
        }

        if (res.status == 'success') {
          this.toaster.success("Channel data successfully updated", "Success", { extendedTimeOut: 10 });
          this.global.selectedChannel.DisplayName = sendData.channelName;
          this.global.selectedChannel.Description = sendData.channelDescription;
          this.modalService.dismissAll(this.global.selectedChannel);
        } else {

          if (res.error)
            this.toaster.error(res.message, "Failure", { timeOut: 5000 });

          this.formChannel.controls["channelName"].enable();
          this.formChannel.controls["channelDescription"].enable();
          this.isChannelExist = true;
          this.isRequestFailed = true;
          if (!res.error)
            this.message = res.message.error.message;
          /* if (res.message.indexOf("NameAlreadyExists") >= 0) {
            this.message = "Channel name already exist";
          } else {
          } */
        }
        this.channelCreateLoader = false;
      }, (error) => {
        this.channelCreateLoader = false;
        this.isRequestError = true;
      })
    }

  }

  public onCreateTab = async (teamId, channelId) => {
    this.teamsService.createNewTabInDB({ channelId: channelId, teamId: teamId, config: this.tabConfigSetting.tabConfig.config, tabName: this.tabConfigSetting.tabConfig.tabName, tabDetail: null }).subscribe(resp => {
      if (resp.status === "success") {
      } else {
        this.isRequestError = true;
      }
    }, (error) => {
    })
  }


  public onNDSCreateFolder() {
    if (this.isNDLoading || !this.isNDSUserAccess) return;
    this.error_message = "Folder name already exists";
    this.isNDSDuplicateFolder = false;
    this.NDSFolderName = "";
    this.showFolderInput = true;
  }


  public NDSValidateFolder() {
    const folderName = this.NDSFolderName;
    this.isNDSDuplicateFolder = this.cabinetFolders.find(cabinetFolder => cabinetFolder.Attributes.Name.toLowerCase().trim() == folderName.toLowerCase().trim()) != undefined ||
      this.workSpaceContents.find(workSpaceContent => workSpaceContent.Attributes.Name.toLowerCase().trim() == folderName.toLowerCase().trim()) != undefined ? true : false;
  }


  public onNDSCreateFolderHide() {
    this.folderCreationError = "";
    this.NDSFolderName = "";
    this.showFolderInput = false;
  }
  public onNDSFolderNameKeyPress(event) {
    if (this.isNDLoading) return;
    if (event.keyCode == 13) this.NDSCreateNewFolder();
  }
  public NDSCreateNewFolder() {
    const NDSourceData = this.ndvs.syncJobSetting.netDocuments
    if (this.isNDLoading || this.NDSFolderName.trim() == "") return;
    this.isNDSFolderCreating = true;
    const cabinetId = this.selectedCabinet.id;
    const containerId = NDSourceData.folderId ? NDSourceData.folderId : NDSourceData.workspaceId;
    const formData = new FormData();
    formData.append("name", this.NDSFolderName.trim());
    formData.append("sanitizeName", "true");
    if (containerId != null && containerId != undefined && containerId != "") formData.append("parent", containerId);
    formData.append("cabinet", cabinetId);
    this.isNDLoading = true;
    this.ndvs.createNewFolder(formData).subscribe((newFolderRes: any) => {
      this.isNDSFolderCreating = false;
      if (newFolderRes.status == "success") {
        this.onNDSCreateFolderHide();
        let newFolderData = { Attributes: { Name: newFolderRes.data.standardAttributes.name, Modified: newFolderRes.data.standardAttributes.modified, Ext: newFolderRes.data.standardAttributes.extension }, DocId: newFolderRes.data.standardAttributes.id, EnvId: newFolderRes.data.standardAttributes.envId, isChecked: false };
        if (NDSourceData.selectAll) newFolderData.isChecked = true
        this.ngZone.run(() => {
          if (NDSourceData.workspaceId == "" && NDSourceData.folderId == "") {
            this.cabinetFolders.unshift(newFolderData);
          } else {
            this.NDSContainerContents.unshift(newFolderData);
            this.workSpaceContents.unshift(newFolderData);
          }
        })
        this.toaster.success(`Folder created successfully`, "", { timeOut: 2000 });
        this.isNDLoading = false;
      } else {
        this.toaster.error(newFolderRes.message && newFolderRes.message != "" ? newFolderRes.message : "Failed to create folder", "", { timeOut: 2000 })
        this.isNDLoading = false;
      }

    });

  }


  listenConfigSetting() {
    this.configSettingListener = event => {
      try {
        if (event.data && event.data.config) {
          if (event.data.tabName == "" || event.data.config.viewType == "" || (event.data.config.viewType == 'normalView' && (!event.data.config.primarySource || (event.data.config.primarySource && event.data.config.primarySource.id == '')))
            || (event.data.config.viewType == 'Tree' && event.data.config.sources && event.data.config.sources.length == 0)) {
            this.tabSetting = false;
          } else {
            this.tabSetting = true;
          }
          this.configSetting = event.data.config;
          this.tabConfiguration = event.data.tabConfiguration;
          let tabName = event.data.tabName ? event.data.tabName : (this.global.selectedTab && this.global.selectedTab.DisplayName) ? this.global.selectedTab.DisplayName : ''
          if (this.formTab.get('tabName').value !== tabName) this.formTab.get('tabName').setValue(tabName);
          this.tabConfigSetting = {
            "tabConfig": { tabId: event.data.entityId, config: JSON.stringify(this.configSetting), tabName: tabName },
            "tenantId": this.global.tenantId
          }
        }
      } catch (e) {
        //console.log(e);
      }
      this.onConfigChange.emit(this.configSetting)
    };
    window.addEventListener('message', this.configSettingListener);
  }
  onChannelSave() {
    let sendData = this.formChannel.value;
    this.onChangeChannelData.emit(sendData);
    this.modalService.dismissAll();
  }
}
