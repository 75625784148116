<app-container-top *ngIf="isEditMode"></app-container-top>

<div class="request-page">
    <div>
        <div class="{{isEditMode ? 'create-request header' : 'create-request'}}" [formGroup]="formTeamReq">
            <div style="width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;" *ngIf="createRequestLoader">
                <div class="loader-container loader-container-absolute">
                    <div class="loader"></div>
                </div>
            </div>
            <div class="form-group row mr-0 ml-0 mb-4">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label">Cabinet</label>
                </div>
                <div class="col-sm-8 col-xl-8">
                    <nd-combo-box [appConfig]="appConfig" [placeholder]="'Select Cabinet name'" [sourceType]="'cabinet'"
                        [isFetching]="cabinet?.isFetching" [lookupData]="cabinet" [selectedItem]="cabinet.selected"
                        (onChange)="onSourceCabinetChange($event)" (onFetch)="fetchCabinets($event)"
                        (onReset)="resetCabinets($event)"></nd-combo-box>
                </div>
            </div>
            <!-- client -->
            <div class="form-group row mr-0 ml-0 mb-4">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label">Client</label>
                </div>
                <div class="col-sm-8 col-xl-8">
                    <nd-combo-box [appConfig]="appConfig" [placeholder]="'Select Client name'" [sourceType]="'client'"
                        [isFetching]="client.isFetching" [lookupData]="client" [selectedItem]="client.selected"
                        (onChange)="onSelectClient($event);" (onFetch)="getClients(cabinet.selected,$event)"
                        (onReset)="reset('client')"></nd-combo-box>
                </div>
            </div>
            <!-- Matter -->
            <div *ngIf="this.matter?.matterInfo" class="form-group row mr-0 ml-0 mb-4">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label">Matter</label>
                </div>
                <div class="col-sm-8 col-xl-8">
                    <div>
                        <nd-combo-box [appConfig]="appConfig" [placeholder]="'Select Matter name'"
                            [sourceType]="'client'" [isFetching]="matter.isFetching" [lookupData]="matter"
                            [selectedItem]="matter.selected" (onChange)="onSelectMatter($event);"
                            (onFetch)="getMatters($event)" (onReset)="reset('matter')"></nd-combo-box>
                    </div>
                </div>
            </div>
            <!-- SharePoint Template -->
            <div class="form-group row mr-0 ml-0 mb-4">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label">SharePoint Site Template</label>
                </div>
                <div class="col-sm-8 col-xl-8">
                    <div>
                        <nd-combo-box [appConfig]="appConfig" [placeholder]="'Select SharePoint Site Template'"
                            [sourceType]="'SharePoint'" [isFetching]="sharePointSiteTemplate.isFetching"
                            [lookupData]="sharePointSiteTemplate" (onChange)="onSelectSharePointSiteTemplate($event);"
                            (onFetch)="getSharePointSiteTemplate()" [selectedItem]="sharePointSiteTemplate.selected"
                            (onReset)="reset('SharePoint')"></nd-combo-box>
                    </div>
                </div>
            </div>

            <div class="form-group row mr-0 ml-0 mb-4">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label">SharePoint Site Collection Name</label>
                </div>
                <div class="col-sm-8">
                    <div class="input-container">
                        <input type="text" class="form-control" formControlName="sharePointSiteCollectionName"
                            placeholder="Give your SharePoint site collection name"
                            (input)="onChangeSPSiteCollectionName()">
                        <div *ngIf="validateSPSiteCollection.isFetching" class="input-loader">
                            <div class="spinner"></div>
                        </div>
                    </div>
                    <div *ngIf="validateSPSiteCollection.message && !validateSPSiteCollection.isFetching"
                        class="siteCollectionAlreadyExist {{validateSPSiteCollection.status}}">
                        {{validateSPSiteCollection.message}}</div>
                </div>
            </div>
            <div class="form-group row mr-0 ml-0 mb-4">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label">Create SharePoint Webpart in Home Page</label>
                </div>
                <div class="col-sm-8">
                    <input type="checkbox" id="isCreateSPWebpartInHomePage" style="display:none"
                        formControlName="isCreateSPWebpartInHomePage"
                        [attr.disabled]="(!formTeamReq.value.sharePointSiteCollectionName?.trim()  ? true : null)" />
                    <label for="isCreateSPWebpartInHomePage" class="check-toggle ml-0 mt-0">
                        <span></span>
                    </label>
                </div>
            </div>
            <!-- Sync in Sharepoint -->
            <div class="form-group row mr-0 ml-0">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label mb-0" for="SPSync">
                        Create SharePoint Sync
                    </label>
                </div>
                <div class="col-sm-8">
                    <input #CreateSPSync type="checkbox" id="isCreateSPSync" formControlName="isCreateSPSync"
                        style="display:none" (change)="onSyncCheckedChange(CreateSPSync.checked,'SPSync')"
                        [attr.disabled]="(!formTeamReq.value.sharePointSiteCollectionName?.trim()  ? true : null)">
                    <label for="isCreateSPSync" class="check-toggle ml-0 mt-0">
                        <span></span>
                    </label>
                </div>
            </div>
            <div *ngIf="formTeamReq.value.isCreateSPSync">
                <app-sync-net-doc-location [editDetails]="sharePointSyncDetails" [syncEnv]="'sharepoint'"
                    (onSyncJobSettingChange)="onSpSyncJobSettingChange($event)"></app-sync-net-doc-location>
            </div>



            <div class="form-group row mr-0 ml-0 mb-4">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label">Team Template</label>
                </div>
                <div class="col-sm-8 col-xl-8">
                    <!-- Template -->
                    <nd-combo-box [appConfig]="appConfig" [placeholder]="'Select Team Template'"
                        [sourceType]="'template'" [isFetching]="template.isFetching" [lookupData]="template"
                        [selectedItem]="template.selected" (onChange)="onSelectTemplate($event);"
                        (onFetch)="getTemplates($event)" (onReset)="reset('template')"></nd-combo-box>
                </div>
            </div>
            <div class="form-group row mr-0 ml-0 mb-4">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label">Team Name <span class="text-danger"> *</span></label>
                </div>
                <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="teamName"
                        placeholder="Give your team a name">
                </div>
            </div>
            <div class="form-group row mr-0 ml-0 mb-4">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label">Description</label>
                </div>
                <div class="col-sm-8">
                    <textarea class="form-control" rows="5" formControlName="description"
                        placeholder="Let people know what this team is all about"></textarea>
                </div>
            </div>
            <div class="form-group row mr-0 ml-0 mb-4">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label">Team Type</label>
                </div>
                <div class="col-sm-8">
                    <div>
                        <select class="form-control" id="typeofteam" formControlName="optionPrivacy">
                            <option value="public">
                                Public (Anyone in your organization can join)
                            </option>
                            <option value="private">
                                Private (People need permission to join)
                            </option>
                            <option value="private">
                                Org-Wide (Everyone in your organization automatically joins)
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- Sync in Team Request -->
            <div class="form-group row mr-0 ml-0">
                <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                    <label class="form-label mb-0" for="teamLevelSync">
                        Create Team Sync
                    </label>
                </div>
                <div class="col-sm-8">
                    <input #teamLevelSync type="checkbox" id="teamLevelSync" formControlName="teamLevelSync"
                        style="display:none" (click)="onSyncCheckedChange(teamLevelSync.checked)">
                    <label for="teamLevelSync" class="check-toggle ml-0 mt-0">
                        <span></span>
                    </label>
                </div>
            </div>
            <div *ngIf="formTeamReq.value.teamLevelSync">
                <app-sync-net-doc-location [editDetails]="editDetails" [syncEnv]="sharepoint"
                    (onSyncJobSettingChange)="onTeamSyncJobSettingChange($event)"></app-sync-net-doc-location>
            </div>
            <!---Channel Add Process-->
            <div class="form-group row mr-0 ml-0 d-flex justify-content-center" *ngIf="!requestFromBulkEdit">
                <div>
                    <button class="btn im-primary-outline-btn" (click)="addChannel();"
                        [disabled]="isEditMode && role=='admin'">
                        <span aria-hidden="true">Add Channel</span>
                    </button>
                </div>
            </div>
            <div formArrayName="channels" *ngFor="let channel of formTeamReq.controls.channels['controls']; let i=index"
                class="channel-holder">
                <div [formGroupName]="i">
                    <div class="channel-head">
                        <div>{{channel.controls.name.value}}</div>
                        <div>
                            <button class="btn btn-remove" title="Remove" (click)="removeChannel(i)">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>
                    <div class="form-group row mr-0 ml-0">
                        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                            <label class="form-label">Channel Name <span class="text-danger"> *</span></label>
                        </div>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" formControlName="name" placeholder>
                        </div>
                    </div>

                    <div class="form-group row mr-0 ml-0">
                        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                            <label class="form-label">Description</label>
                        </div>
                        <div class="col-sm-8">
                            <textarea type="text" rows="5" class="form-control" formControlName="description"
                                placeholder="Let people know what this channel is all about"></textarea>
                        </div>
                    </div>

                    <div class="form-group row mr-0 ml-0">
                        <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                            <label class="form-label">Privacy</label>
                        </div>
                        <div class="col-sm-8">
                            <div>
                                <select class="form-control" id="membershipType" formControlName="membershipType">
                                    <option value="Standard">
                                        Standard
                                    </option>
                                    <option value="Private">
                                        Private
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!formTeamReq.value.teamLevelSync">
                        <div class="form-group row mr-0 ml-0">
                            <div class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                                <label class="form-label mb-0">
                                    Create Channel Sync
                                </label>
                            </div>
                            <div class="col-sm-8">
                                <input type="checkbox" id="channelSync_{{i}}" formControlName="sync"
                                    style="display:none" (click)="onChangeChannelSync($event,i)">
                                <label for="channelSync_{{i}}" class="check-toggle ml-0 mt-0">
                                    <span></span>
                                </label>
                            </div>
                        </div>

                        <div *ngIf="channel.value.sync">
                            <app-sync-net-doc-location [editDetails]="editDetails" [syncEnv]="teams"
                                (onSyncJobSettingChange)="onChannelSyncJobSettingChange($event,i)"></app-sync-net-doc-location>
                        </div>
                    </div>
                    <!-- TAB CONFIG in Team Request -->
                    <div class="form-group row mr-0 ml-0 d-flex justify-content-center">
                        <div>
                            <button class="btn im-primary-outline-btn" (click)="openApps(appsModal,i)"
                                [disabled]="isEditMode && role=='admin'">
                                <span aria-hidden="true" [(ngModel)]="tabCreatenableorDiasble"> Add Tab</span>
                            </button>

                        </div>
                    </div>



                    <div formArrayName="tabs" *ngFor="let tab of channel.controls.tabs.controls; let j=index">
                        <div [formGroupName]="j">
                            <div class="form-group row mr-0 ml-0">
                                <div
                                    class="col-sm-4 position-relative d-flex justify-content-between align-items-center">
                                    <label class="form-label">Tab Name <span class="text-danger"> *</span></label>
                                </div>
                                <div class="col-sm-8 d-flex">
                                    <input type="text" class="form-control mr-2" formControlName="name" placeholder>
                                    <div style="display: flex;">
                                        <button class="btn btn-icon im-white-outline-btn waves-effect btn-edit-remove"
                                            type="button" *ngIf="tab.value.app!='planner'">
                                            <img (click)="onEditTab(modalTab,tab,i,j)"
                                                src="assets/images/edit_black_24dp.svg">
                                        </button>
                                        <button class="btn btn-remove btn-edit-remove" title="Remove"
                                            (click)="removeTab(i,j)">
                                            <span aria-hidden="true" style="font-size: 20px;">×</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #modalTab let-createTabModal>
                        <div class="create-request">
                            <button [disabled]="tabCreateLoader" class="btn btn-remove btn-edit-remove createTabClose"
                                (click)="createTabModal.close('no')">
                                <img src="./../../../assets/images/icons/modal-close.svg">
                            </button>
                            <create-tab [tabsList]="selectedTab" [tabDetail]="selectedTab"
                                (onConfigChange)="setConfigChange($event)" [creationFromTeam]="true"></create-tab>
                            <div class="createrequest-popup-footer">
                                <button class="btn im-primary-outline-btn" title="Cancel"
                                    (click)="createTabModal.close('no')">
                                    Cancel
                                </button>
                                <button class="btn im-primary-btn" title="Save"
                                    [disabled]="(!configSetting && !configSetting?.tabConfig?.config)"
                                    (click)="onSaveTab(tab,createTabModal);">
                                    Save
                                </button>

                            </div>

                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="createrequest-popup-footer">
                <button class="btn im-primary-outline-btn" (click)="onCancel()" [disabled]="createRequestLoader">
                    {{isFromRequestPage||requestFromBulkEdit?'Close':'Back'}}
                </button>
                <button *ngIf="!isEditMode" class="btn im-primary-btn" [disabled]="!formTeamReq.value.teamName.trim()"
                    (click)="onCreateTeamRequest()">
                    {{isBulkEditMode?'Save':'Create'}}
                    <img *ngIf="createRequestLoader" src="assets/images/Spinner.gif" class="search-button-img">
                </button>
                <button *ngIf="isEditMode" class="btn im-primary-btn" [disabled]="!formTeamReq.value.teamName.trim()"
                    (click)="onUpdateTeamRequest()">
                    Update
                    <img *ngIf="createRequestLoader" src="assets/images/Spinner.gif" class="search-button-img">
                </button>
                <!-- <button (click)="onTestingData()">Testing</button> -->
            </div>
        </div>
    </div>


    <ng-template #appsModal let-modal>
        <team-apps [modal]="modal" [isReturnSelectedApp]="true" (onSelectedApp)="onSelectedApp($event)"></team-apps>
    </ng-template>