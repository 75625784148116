import { Component, Input, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'team-apps',
  templateUrl: './team-apps.component.html',
  styleUrls: ['./team-apps.component.scss']
})
export class TeamAppsComponent implements OnInit {
  @Input("modal") modal: any;
  @Input("group") group: any = {};
  @Input("channel") channel = {};
  @Input("isReturnSelectedApp") isReturnSelectedApp: boolean = false;
  @Input("onTabCreated") onTabCreated: EventEmitter<any>;
  @Output() onNetDocShareAppChoosed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('createPlannerTabModal') createPlannerTabModal!: TemplateRef<any>;
  apps = [
    { title: "netDocShare", value: "netDocShare", icon: "assets/images/netDocShare-logo-main-new.svg" },
    { title: "Planner", value: "planner", icon: "assets/images/planner.png" }
  ];

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onChooseApp(app) {
    this.closeModal(this.isReturnSelectedApp ? app : null);
    if (!this.isReturnSelectedApp) {
      if (app.value == "netDocShare") this.onNetDocShareAppChoosed.emit(app);
      else if (app.value == "planner") {
        this.modalService.open(this.createPlannerTabModal).result.then(res => {
        });;
      }
    }
  }
  closeModal(app = null) {
    this.modal?.close(app);
  }
}
