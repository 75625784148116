<app-container-top></app-container-top>

<div class="container">
    <div style="width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;" *ngIf="bulkEditTeamLoader">
        <div class="loader-container loader-container-absolute">
            <div class="loader"></div>
        </div>
    </div>
    <div class="col-md-12">
        <button type="button" class="btn im-primary-outline-btn" (click)="back(modalunload)">Back</button>
        <button style="float: right;" type="button" class="btn im-primary-btn" (click)="addteam()">+ Team</button>
    </div>
    <div class="col-md-12">
        <form [formGroup]="myForm">
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th class="bulkAction-Col1">Team Details</th>
                        <th class="bulkAction-Col2">Channel Details</th>
                        <th class="bulkAction-Col3">Tab Details</th>
                        <th class="bulkAction-Col4">Action</th>
                    </tr>
                </thead>
                <tbody formArrayName="teams">
                    <tr *ngFor="let team of teams.controls; let i = index" [formGroupName]="i">
                        <td class="bulkAction-Col1">
                            <div class="input-group mb-2">
                                <input type="text" class="form-control" (keyup)="checkTeamName(i)"
                                    formControlName="teamName" placeholder="Enter Team Name *" aria-label="Team Name"
                                    aria-describedby="button-addon2">
                                <div class="input-group-append">
                                    <button title="Config More" class="btn im-primary-btn"
                                        [disabled]="team.get('teamName').invalid || team.get('status').value=='success'"
                                        type="button" (click)="openTeam(modalTeam,team.get('teamName').value,i)"
                                        id="button-addon2">
                                        <svg class="svgIcon" xmlns="http://www.w3.org/2000/svg" width="19.414"
                                            height="19.959" viewBox="0 0 19.414 19.959">
                                            <path id="Icon_material-settings" data-name="Icon material-settings"
                                                d="M20.53,13.957a6.881,6.881,0,0,0,0-1.956l2.106-1.647a.5.5,0,0,0,.12-.639l-2-3.453a.5.5,0,0,0-.609-.22l-2.485,1a7.291,7.291,0,0,0-1.687-.978L15.6,3.419A.487.487,0,0,0,15.111,3H11.119a.487.487,0,0,0-.489.419l-.379,2.645a7.667,7.667,0,0,0-1.687.978l-2.485-1a.487.487,0,0,0-.609.22l-2,3.453a.492.492,0,0,0,.12.639L5.7,12a7.914,7.914,0,0,0-.07.978,7.914,7.914,0,0,0,.07.978L3.595,15.6a.5.5,0,0,0-.12.639l2,3.453a.5.5,0,0,0,.609.22l2.485-1a7.291,7.291,0,0,0,1.687.978l.379,2.645a.487.487,0,0,0,.489.419h3.992a.487.487,0,0,0,.489-.419l.379-2.645a7.667,7.667,0,0,0,1.687-.978l2.485,1a.487.487,0,0,0,.609-.22l2-3.453a.5.5,0,0,0-.12-.639L20.53,13.957Zm-7.415,2.515a3.493,3.493,0,1,1,3.493-3.493A3.5,3.5,0,0,1,13.115,16.472Z"
                                                transform="translate(-3.406 -3)" fill="currentColor" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div class="row"
                                *ngIf="team.get('teamName').errors?.required && team.get('teamName').touched">
                                <div class="col-sm-12 error">
                                    Team Name is required
                                </div>
                            </div>
                            <div class="row" *ngIf="team.get('teamName')?.errors?.duplicate">
                                <div class="col-sm-12 error">
                                    {{duplicateMsg}}
                                </div>
                            </div>



                            <!-- <div class="form-group row mr-0 ml-0 mb-4 d-flex">
                                <div class="col-sm-10">
                                    <input class="form-control" (keyup)="checkTeamName(i)" formControlName="teamName" placeholder="Team Name" />
                                </div>
                                <div class="col-sm-2">
                                    <button title="Config More" class="btn btn-icon icon-grid waves-effect ml-2 me-2" [disabled]="team.get('teamName').invalid || team.get('status').value=='success'" type="button" (click)="openTeam(modalTeam,team.get('teamName').value,i)"><img
                                            src="assets/images/icons/setting-icon.svg">
                                    </button>
                                </div>
                                <div *ngIf="team.get('teamName').errors?.required && team.get('teamName').touched" class="col-sm-10 error">
                                    Team Name is required
                                </div>
                                <div *ngIf="team.get('teamName')?.errors?.duplicate" class="col-sm-10 error">
                                    {{duplicateMsg}}
                                </div>
                            </div> -->
                        </td>
                        <td class="bulkAction-Col2">
                            <div class="input-group mb-2">
                                <input type="text" class="form-control" formControlName="channelname"
                                    placeholder="Enter Channel Name" aria-label="Channel Name">
                                <div class="input-group-append">
                                    <button title="Config More" class="btn im-primary-btn"
                                        [disabled]="team.get('channelname').value.trim()==''||team.get('teamName').invalid || team.get('status').value=='success'"
                                        type="button" (click)="addChanneltoTeam(team.get('channelname').value,i)">
                                        <svg class="svgIcon" xmlns="http://www.w3.org/2000/svg" width="12.325"
                                            height="12.284" viewBox="0 0 12.325 12.284">
                                            <g id="Group_528" data-name="Group 528" transform="translate(0 0)">
                                                <path id="Path_543" data-name="Path 543"
                                                    d="M41.551,325.514H36.275v5.279H34.5v-5.279H29.226v-1.732H34.5v-5.274h1.774v5.274h5.275Z"
                                                    transform="translate(-29.226 -318.509)" fill="currentColor" />
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <!-- <div class="form-group row d-flex">
                                <div class="col-sm-10">
                                    <input class="form-control" formControlName="channelname" placeholder="Channel Name" />
                                </div>
                                <div class="col-sm-2">
                                    <button title="Add Channels" [disabled]="team.get('channelname').value==''||team.get('teamName').invalid || team.get('status').value=='success'" class="btn btn-icon icon-grid im-primary-outline-btn waves-effect ml-2 me-2" type="button" (click)="addChanneltoTeam(team.get('channelname').value,i)"><img
                                            src="assets/images/plus.svg" style="color: #fff;">
                                    </button>
                                </div>
                            </div> -->
                            <div class="form-group row d-flex">
                                <div class="col-sm-12">
                                    <div class="list-holder">
                                        <div *ngIf="channelsLoader" class="section-loader">
                                            <img src="assets/images/Spinner.gif">
                                        </div>
                                        <div *ngIf="isChannelsRequestFailed">Channels data fetching error</div>
                                        <!-- <div class="col-sm-12"
                                            *ngIf="team.get('teamName').value!='' && teams?.controls[i]?.get('channels')?.controls.length==0">
                                            Channels not found</div> -->
                                        <ul class="list-group list-border-0 team-channel-list">
                                            <li *ngFor="let channel of teams?.controls[i]?.get('channels').controls; let channelIndex=index;"
                                                [formGroupName]="channelIndex"
                                                class="{{(channel.controls.name.value==teams?.controls[i]?.get('selectedChennal')?.value?.value?.name)?'list-group-item d-flex justify-content-between align-items-center teams-channels-list-show-actions selected-channel':'list-group-item d-flex justify-content-between align-items-center teams-channels-list-show-actions'}}">
                                                <div class="col-12 p-0 d-flex teams-channels-name"
                                                    title="{{channel.controls.name.value}}"
                                                    (click)="onSelectChannel(i,channelIndex,channel)">
                                                    <img src="assets/images/icons/channel-icon.svg"
                                                        class="default-icon">
                                                    <span
                                                        class="overflow-ellipsis">{{channel.controls?.name?.value}}</span>
                                                </div>
                                                <div class="col-auto p-0 d-flex">
                                                    <div class="elipse-action">
                                                        <div ngbDropdown placement="bottom-right"
                                                            class="d-inline-block"><img ngbDropdownToggle
                                                                src="./../../../assets/images/icons/ellipse-hover-icon.svg"
                                                                width="25px">
                                                            <div ngbDropdownMenu><button
                                                                    [disabled]="team.get('status').value=='success'"
                                                                    ngbDropdownItem
                                                                    (click)="onEditChannel(modalChannel,channel,channelIndex,i)">
                                                                    Edit
                                                                </button><button
                                                                    [disabled]="team.get('status').value=='success'"
                                                                    ngbDropdownItem
                                                                    (click)="removeChannel(i,channelIndex,modalDeleteChannel)">
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button [disabled]="team.get('status').value=='success'"
                                                    class="btn btn-icon im-primary-btn  sync-btn waves-effect ml-2 me-2"
                                                    type="button"
                                                    title="{{(teams?.controls[i]?.get('teamLevelSync')?.value?'Team level Sync Enabled':'Channel Sync')}}"
                                                    [disabled]="teams?.controls[i]?.get('teamLevelSync')?.value"
                                                    (click)="onEditSync(modalSync,i,channelIndex,channel )"><img
                                                        src="assets/images/sync.svg">
                                                </button>
                                                <button [disabled]="team.get('status').value=='success'"
                                                    title="Add Tabs"
                                                    class="btn btn-icon im-white-outline-btn  waves-effect ml-2 me-2"
                                                    type="button"
                                                    (click)="openApps(appsModal,modalTab,createPlannerTabModal,i,channelIndex,channel)"><img
                                                        src="assets/images/plus.svg">
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="bulkAction-Col3">
                            <div class="row">
                                <div class="col-sm-12 mb-2">
                                    <span style="color: var(--im-grey-text);"
                                        *ngIf="teams?.controls[i]?.get('selectedChennal')?.value?.value?.name!=undefined">{{team.get('teamName')?.value
                                        }} <strong class="mx-1">></strong>
                                        {{teams?.controls[i]?.get('selectedChennal')?.value?.value?.name }}</span>
                                </div>
                            </div>
                            <!-- <div class="row col-sm-12"
                                *ngIf="team.get('teamName').value!='' && (teams?.controls[i]?.get('channels')?.controls[team?.get('selectedChannelIndex')?.value]?.get('tabs')?.controls.length==0 ||teams?.controls[i]?.get('channels')?.controls.length==0)">
                                Tabs not found
                            </div> -->
                            <div class="form-group row d-flex">
                                <div class="col-sm-12">
                                    <div class="col-12 p-0 middle-list-height team-list">
                                        <div class="list-holder">
                                            <div *ngIf="channelsLoader" class="section-loader">
                                                <img src="assets/images/Spinner.gif">
                                            </div>
                                            <ul class="list-group list-border-0 team-channel-list">
                                                <li *ngFor="let tab of  teams?.controls[i]?.get('channels')?.controls[(team?.get('selectedChannelIndex')?.value |number)]?.get('tabs')?.controls; let tabIndex=index"
                                                    class="list-group-item d-flex justify-content-between align-items-center teams-channels-list-show-actions">
                                                    <div class="col-12 p-0 d-flex align-items-center teams-channels-name"
                                                        title="{{tab?.value?.name}}">
                                                        <img src="assets/images/icons/channel-icon.svg"
                                                            class="default-icon">
                                                        <span class="overflow-ellipsis">{{tab?.value?.name}}</span>
                                                    </div>
                                                    <div class="col-auto p-0 d-flex">
                                                        <div class="elipse-action">
                                                            <div ngbDropdown placement="bottom-right"
                                                                class="d-inline-block"><img ngbDropdownToggle
                                                                    src="./../../../assets/images/icons/ellipse-hover-icon.svg"
                                                                    width="25px">
                                                                <div ngbDropdownMenu>
                                                                    <button *ngIf="tab.value.app != 'planner'"
                                                                        [disabled]="team.get('status').value=='success'"
                                                                        ngbDropdownItem
                                                                        (click)="onEditTab(modalTab,tab,tabIndex)">
                                                                        Edit
                                                                    </button>
                                                                    <button
                                                                        [disabled]="team.get('status').value=='success'"
                                                                        ngbDropdownItem
                                                                        (click)="removetab(i,tabIndex,modalDeleteTab)">
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="bulkAction-Col4">
                            <div *ngIf="team.get('status').value==''">
                                <button title="Delete Team"
                                    class="btn btn-icon icon-grid im-white-outline-btn waves-effect btn-edit-remove"
                                    type="button" (click)="removeteam(modalDelete,i)">
                                    <img src="assets/images/delete_forever_black_24dp.svg" />
                                </button>
                            </div>
                            <div *ngIf="team.get('status').value=='success'">
                                <button title="Team Created Successfully!."
                                    class="btn btn-icon icon-grid  waves-effect btn-edit-remove" type="button">
                                    <img src="assets/images/check_icon.svg" />
                                </button>
                            </div>
                            <div *ngIf="team.get('status').value=='failed'">
                                <button title="{{team.get('statusMessage').value}}"
                                    class="btn btn-icon icon-grid  waves-effect btn-edit-remove" type="button">
                                    <img src="assets/images/error-auth.png" />
                                </button>
                            </div>
                            <div *ngIf="team.get('status').value=='process'" class="loading-div w-100">
                                <button title="Team Creation in Progress"
                                    class="btn btn-icon icon-grid  waves-effect btn-edit-remove" type="button">
                                    <img class="info-icon" src="assets/images/Spinner.gif" />
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style="text-align: right;"><button type="button" class="btn im-primary-btn"
                    [disabled]="myForm.invalid||duplicateMsg||isDisableSubmit" (click)="onSubmit()">Submit</button>
            </div>
        </form>
    </div>
</div>

<ng-template #modalChannel let-modal>
    <create-channel [requestFromBulkEdit]='true' [channelDetail]="channelDetail"
        (onChangeChannelData)="onChangeChannelData($event)"></create-channel>
</ng-template>
<ng-template #modalSync let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Sync Config</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body" style="overflow-y: scroll;height: 450px;">
        <app-sync-net-doc-location [isbulkEdit]="true" (onSyncJobSettingChange)="onSyncJobSettingChange($event)"
            [syncEnv]="'teams'" [editDetails]="editSyncDetails"></app-sync-net-doc-location>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
            Cancel
        </button>
        <button type="button" class="btn im-primary-btn" (click)="onSaveSync()">
            Save Sync
        </button>
    </div>
</ng-template>

<ng-template #modalDelete let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Team?</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body">Are you sure you want to delete this Team?</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
            Cancel
        </button>
        <button type="button" class="btn im-primary-btn" (click)="modal.close('yes')">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #modalDeleteChannel let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Channel?</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body">Are you sure you want to delete this Channel?</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
            Cancel
        </button>
        <button type="button" class="btn im-primary-btn" (click)="modal.close('yes')">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #modalDeleteTab let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Tab?</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body">Are you sure you want to delete this Tab?</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
            Cancel
        </button>
        <button type="button" class="btn im-primary-btn" (click)="modal.close('yes')">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #modalunload let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Page Reload Confirmation</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body">Are you sure you want to reload this page? Reloading may affect the ongoing team creation
        process.</div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">
            Cancel
        </button>
        <button type="button" class="btn im-primary-btn" (click)="modal.close('yes')">
            Reload
        </button>
    </div>
</ng-template>
<ng-template #modalTeam let-modal style="max-width: 700px !important;">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Team Details</h4>
        <div>
            <button class="btn btn-remove remove-channel" title="Remove" (click)="modal.close('no')">
                <span aria-hidden="true" class="text-white">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body" style="overflow-y: scroll;height: 450px;">
        <app-create-request [requestFromBulkEdit]='true' [isBulkEditMode]="isBulkEditMode" [teamDetail]="teamDetails"
            [teamName]="selectedTeamName" (onSaveTeam)="onUpdateTeam($event,modalTeam)"></app-create-request>
    </div>
</ng-template>

<ng-template #modalTab let-modal>
    <create-tab [isBulkEdit]="true" [modal]="modal" (onConfigChange)="onAddTab($event)"></create-tab>
</ng-template>

<ng-template #appsModal let-modal>
    <team-apps [modal]="modal" [isReturnSelectedApp]="true" (onSelectedApp)="onSelectedApp($event)"></team-apps>
</ng-template>

<ng-template #createPlannerTabModal let-modal>
    <create-planner-tab [modal]="modal" [isReturnConfig]="true">
    </create-planner-tab>
</ng-template>