import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LogService } from './log.service';
import { GlobalService } from "./global.service";
import { NetdocshareLocalstorageService } from './netdocshare-localstorage.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SpService {

  constructor( public _http: HttpClient,public global: GlobalService,private logService: LogService,
    public netLocalStorage: NetdocshareLocalstorageService) { }

  public getSharePointConnection() {
    const url = `${environment.spserviceUrl}/api/sharepoint/connection/${this.netLocalStorage.licenseInfo.clientId}`;
    let requestOut: EventEmitter<any> = new EventEmitter<any>();
    this._http.get(url).subscribe((res: any) => {
      if (res.result == "success") {
        this.logService.updateLogMessage("SharePoint connection check API request success");
        const spConnection = res.data?.find(item=>item.jobAssigned);
        requestOut.emit({ status: "success", data: spConnection ? spConnection : res.data[0] ? res.data[0] : {}, message: res.message })
      } else {
        this.logService.updateLogMessage("SharePoint connection check API request failed");
        requestOut.emit({ status: "failed", message: res.message })
      }
    }, (err) => {
      requestOut.emit({ status: "failed", data: "Server to  busy, Try again later" })
    })
    return requestOut;
  }

  public getSharePointSiteTemplate(connectionId) {
    const url = `${environment.spserviceUrl}/api/sharepoint/sitetemplate`;
    let requestOut: EventEmitter<any> = new EventEmitter<any>();
    this._http.post(url, { connectionId: connectionId }).subscribe((res: any) => {
      if (res.result == "success") {
        requestOut.emit({ status: "success", data: res.data })
      } else {
        requestOut.emit({ status: "failed", data: null })
      }
    }, (err) => {
      requestOut.emit({ status: "failed", data: null })
    })
    return requestOut;
  }
  public validateSPSiteCollectionName(connectionId, newSiteCollectionName) {
    const url = `${environment.spserviceUrl}/api/sharepoint/validate/sitecollection`;
    let requestOut: EventEmitter<any> = new EventEmitter<any>();
    this._http.post(url, { connectionId: connectionId, newSiteCollectionName }).subscribe((res: any) => {
      if (res.result == "success") {
        requestOut.emit({ status: "success", message: res.message })
      } else {
        requestOut.emit({ status: "failed", message: res.message })
      }
    }, (err) => {
      requestOut.emit({ status: "failed", message: "Unable to validate, Please try again later" })
    })
    return requestOut;
  }

}
